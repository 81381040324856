import api from '@/axios.js'

class UserAPI{
    static userLogin(phone,authToken){
        return new Promise(async(resolve,reject)=>{
            try{
                const res = await api.post('firebase-login',{                   
                        phone_number: phone,
                        firebase_token: authToken
                })
                const data = res.data
                resolve(data)
            }catch(error){
                reject(error)
            }
        })
    }
    // gets user with loan and balance information
    static getUser(){
        return new Promise(async (resolve, reject)=>{
            try {
                const res = await api.get('profile',{
                    headers: {
                        'Authorization': 'Bearer '+ window.localStorage.getItem("token")
                    },
                })
                const data = res.data
                resolve(data)
            } catch (error) {
                reject(error)
            }
        })
    }
    // registers new user
    static registerUser(userInfo, phoneNumber){
        return new Promise(async(resolve, reject)=>{
            try {
                const res = await api.post('register', {
                        user_name: userInfo.user_name,
                        first_name:userInfo.first_name,
                        last_name: userInfo.last_name,
                        email:userInfo.email,
                        phone_number: phoneNumber,
                        password:userInfo.password,
                        password_confirmation: userInfo.password_confirmation
                    })
                const data = res.data
                console.log("data from the registration")
                console.log(data)
                resolve(data)
            } catch (error) {
                reject(error)
            }
        })
    }

    // gets users notifications
    static getNotifications(){
        return new Promise(async(resolve,reject)=>{
            try {
                const res = await api.get('/notifications',{
                    headers: {
                        'Authorization': 'Bearer '+ window.localStorage.getItem("token")
                    },
                })
                const data = res.data.data
                resolve(data)
            } catch (error) {
                reject(error)
            }
        })
    }
}

export default UserAPI