const lang={
en:{
    print: 'Print reciept',
    categories:"Categories",
    welcome:"Welcome!", //
    buy_now:'Buy Now', //
    what_are_you_looking_for:'Looking For ?', 
    register_now:'Register Now',
    free_transport:'Free Transport', //
    loan_service:'Loan Service', 
    loan_service_unavailable: "Sorry! Loan service is not available now",
    error_fetch_loan_description: "Sorry. We are unable to fetch your loan balance",
    error_fetch_loan: "Loan fetch error",
    customer_service:'Customer Service', //
    large_number_of_items:'Large Number Of Items', //
    fair_price:'Fair Price', //
    free_delivery_in_your_place:'Free Delivery In Your Place', //
    you_can_buy_in_debt:'You Can Buy In Debt', //
    guaranted_service_24_7:'24/7 Guaranteed Service', //
    large_number_of_items_in_cheap_price:'Large Number Of Items In Cheap Price', //
    quality_product_in_low_price:'Quality Product In Low Price', //

    buy_from_choosen_categories:'Buy From Choosen Categories', // 
    best_selling_items:'Best Selling Items', //
    package_items:'Packages we offer',//
    shop_these_packages_with_discount:'Shop These Packages With Discount',//
    subscribe_to_our_news_letter:'Subscribe To Our News Letter', // 
    always_be_the_first_to_hear_new_inforation:'Always Be The First To Hear New Information About The Products You Buy', //
    buy:'Buy', // 
    package:'Package',
    items_in_package: 'Items inside this package',
    food_and_drink:'Food And Drink', // 
    beauty_products:'Beauty Products', //
    become_a_seller:'Become A Seller', //
    call_center:'Call Center', //
    ordered_products:'Ordered Products', 
    order_history: "Order history", 
    empty_order_history: "No Order History Yet",
    time: "Time",
    return_policy:'Return Policy',//
    contact_us:'Contact Us', // 
    about_seregela:'About Seregela', // 
    responsibility:' Our Responsibility', //
    vision:'Vision', //
    search:'Search', //
    when_you_buy_monthly_package:'When You Buy 5 monthly Package',
    plus_one:'+ Free',
    dear_customer_we_delivery_in_your_place:'Dear customers, order the package from your place, We deliver quickly. 100% prepared from natural resources; We prepare as much as you want',
    language:'Language',
    dont_have_money:"You don't have enough money; Don't worry, sign up for our loan service!", //
    buy_package_from_one_place:'Buy Packages from one place without the hassle!', //
    available_products:"Available Products",
    favourite:'Favourite',
    products:'Products',
    product:'Product',
    continue_shopping:'Continue Shopping',
    size:'Size',
    discount:'Discount',
    the_price_of_one:'The Price Of One',
    info:'Info',
    transport:'Transport',
    payment:'Payment',
    continue:'Continue',
    address:"Address",
    pay_by_seregela_debt:'Pay By Seregela Debt',
    other_payment_option:'Other Payment Option',
    your_balance:'Remaining Balance',
    your_loan_balance: 'Remaining loan balance',
    payment_option: 'Payment Option',
    payment_options:'Payment Options',
    payment_approval:'Payment Approval',
    payment_failed: 'Payment was not successfull',
    retry_payment: 'If you want to retry the payment again please press the button right below.',
    price_range: "Price range",
    start: "Start",
    end: "End",
    current_location: "Current Location",
    current_location_empty: 'please insert current location',
    different_location: "Different Location", 
    delivery_cost: 'Delivery Cost',
    payment_method: 'Payment Method',
    order_cancelled: 'Order Cancelled',
    address_registered: 'Address registered successfully',
    address_registration_failed: 'Address not registered',
    loading: 'Loading',
    payment_successfull: "Payment Successfully processed",
    phone_already_registered: 'the phone number is in use',
    email_already_exists: 'The email has already been taken',
    date: 'Date',
    status: "Status",


    first_string:"Abebe",
    second_string:"Kebede",
    you_can_change_language_after: "You can change language after loggedIn.",
    anything_you_need_at_your_finger_tips: "Anything you need at your finger tips",
    real_discount_from_packages:"Real discount from Packages",
    delivered_to_your_door:"Delivered to your Door",
    get_started:"Get Started",
    name:"Name",
    price:"Price",
    detail:"Detail",
    delivery_type:"Delivery Type",
    delivery_method_empty: "Please select your delivery choice",
    unit_price: "Unit price",
    total_price:"Total Price",
    order_date:"Order Date",
    birr:"Birr",
    product_added:"Product Added",
    product_added_description:"have been added to cart",
    product_removed:"Product Removed",
    product_removed_description:"have been removed from cart",
    package_added:"Package Added",
    package_added_description:"have been added to cart",
    package_removed:"Package Removed",
    package_removed_description:"have been removed from cart",
    try_again_later:"Try again later",
    something_went_wrong:"Something went wrong",
    connection_error:"Connection error",
    check_internet_connection:"Check your internet connection",
    you_ve_logged_out:"You've logged out",
    hope_to_see_you_soon:"Hope to see you soon",
    profile_edit_successful:"Profile edit successful",
    profile_edit_successful_description:"Your profile have successfully been edited",
    added_to_wish_list:"Added to wish list",
    added_to_wish_list_description:"have been added to wish list",
    removed_from_wish_list:"Removed from wish list",
    removed_from_wish_list_description:"have been removed from wish list",
    cart:"Cart",
    no_items_in_cart:"No items in your cart",
    no_items_in_cart_description:"You have to add products in your cart to checkout",
    checkout:"Checkout",
    checkout_second:"checkout",
    sub_total:"Sub total",
    tax:"Tax",
    delivery:"Delivery",
    delivery_date: "Delivery date",
    next_page:"Next page",
    total:"Total",
    shop_by_suppliers:"Shop by suppliers",
    no_items_via_this_filter:"No items via this filter",
    no_products:"No Products",
    all:"All",
    basic_info:"Basic Info",
    next:"Next",
    convenience:"Convenience",
    delivered_in_one_week:"Delivered in 1 week",
    basic:"Basic",
    delivered_in_business_days:"Delivered in 2 to 3 business days",
    seregela_fast:"Seregela fast",
    delivered_in_one_day:"Delivered in 1 day",
    credit:"Credit",
    others:"Others",
    choose_payment_method:"Choose payment method",
    request_more:"Request more",
    before_purchase:"Before purchase",
    after_purchase:"After purchase",
    finish:"Finish",
    not_corporate:"Not Corporate",
    not_corporate_description:"To use pay with credit feature, you have to have a corporate id",
    no_loan_balance:"No loan balance",
    no_loan_balance_description:"You have no loan balance to make the purchase",
    insufficient_balance:"Insufficient balance",
    insufficient_balance_description:"Your balance is insufficient. Your balance: ",
    pay_with_credit:"Pay with credit",
    pay_the_difference:"Pay the difference with other methods",
    you_can_pay_with_credit_if_you_have_corporate_id:"You can pay with credit if you have corporate id",
    pay_with_others:"Pay with others",
    telebirr_awashbirr_cbebirr:"Telebirr, Awashbirr, CBEbirr",
    approve_delivery:"Approve Delivery",
    approve_and_pay:"Approve and Pay",
    approve:"Approve",
    cancel:"Cancel",
    product_ordered_successfully:"Products ordered successfully",
    go_home:"Go home",
    go_to_home_page:"Go to home page?",
    yes:"Yes",
    no:"No",
    edit_profile:"Edit profile",
    what_do_you_need:"What do you need?",
    take_your_groceries:"Take your groceries",
    pay_later:"Pay later",
    learn_more:"Learn more",
    frequently_purchased_items:"Frequently purchased items",
    packages:"Packages",
    for_you:"For you",
    more:"More",
    amharic:"Amharic",
    english:"English",
    help_center:"Help Center",
    problem_try_again_later:"There seems to be a problem. Try again later.",
    order_list:"Orders list",
    orders:"Orders",
    notifications:"Notifications",
    settings:"Settings",
    change_password:"Change pin",
    back:"Back",
    left_in_stock:"Left in stock",
    out_of_stock:"Out of stock",
    not_available_in_stock: "is not available in stock at the moment",
    add_to_cart:"Add to cart",
    description:"Description",
    wish_list:"Wish List",
    no_items_in_wish_list:"No items in wish list",
    password_change_successful:"Password change successful",
    password_change_successful_description:"You have successfully changed your password",
    change:"Change",
    login:"Login",
    forgot_password:"Forgot password?",
    new_to_seregela_majet:"New to Seregela Gebeya?",
    login_successful:"Login Successful",
    login_successful_description:"Welcome to Seregela Gebeya!",
    personal_info:"Personal Information",
    this_information_is_needed_for_delivery_service:"This information is needed for delivery service",
    register:"Register",
    already_a_member:"Already a member?",
    registration_successful:"Registration Successful",
    log_in:"LogIn",
    log_out:"Log out",
    home:"Home",
    email_hint:"Email",
    email_error:"Incorrect email address (*****@*****.com)",
    password_hint:"Password",
    password_empty:"Fill your password",
    old_password_hint:"Old Password",
    old_password_empty:"Fill your old password",
    new_password_hint:"New Password",
    new_password_empty:"Fil your new password",
    password_confirm_hint:"Confirm Password",
    password_confirm_empty:"You need to confirm your password",
    password_confirm_error:"Password confirmation is wrong",
    user_name_hint:"User Name",
    user_name_empty:"Fill your username",
    user_name_error:"No space is allowed",
    user_name_exist: "username already in use",
    first_name_hint:"First Name",
    first_name_empty:"Fill your first name",
    last_name_hint:"Last Name",
    last_name_empty:"Fill your last name",
    phone_no_hint:"Phone Number",
    phone_no_empty:"Fill your phone number",
    phone_no_error:"Phone format not correct(2519********)",
    password_length_error:"8 or more characters",
    city_hint:"City",
    city_empty:"Fill your city",
    sub_city_hint:"Sub-city",
    sub_city_empty:"Fill your sub-city",
    woreda_hint:"Woreda",
    woreda_empty:"Fill your woreda",
    neighborhood_hint:"Neighborhood",
    neighborhood_empty:"Fill your neighborhood",
    house_no_hint:"House Number",
    house_no_empty:"Fill your house number",
    quantity:"Quantity",
    seregela:"Seregela",//
    majet:"Gebeya",
    invalid_phone_number:"Invalid phone number",
    sorry: "Sorry",
    we_couldnt_find: "We couldn't find the page you were looking for",
    many_request: "Too many requests",
    payment_finished_cbebirr: "When you finish paying on CBE Birr please press the button below to continue",
    payment_canceled: "CBE Birr transaction has been canceled",
    client_dont_have_cbebirr: "You don't have a cbe birr account",
    cbebirr_authentication_error: "CBE Birr authentication error",
    hold_your_phone: "Have your phone in your hand",
    retry_cbebirr: 'Retry CBE birr',
    cbebirr_dormant: 'Your CBE Birr account is dormant',
    cbeBirr_limit_reached: 'CBE Birr retry limit reached. Try again later',
    enter_code: 'Enter the code sent',
    resend_code: 'Resend',
    terms_and_services: 'By tapping continue you agree to our Terms and Privacy Policy.',
    terms_click_to_accept: 'I agree to Bunna Asbeza\'s Terms and Condition',
    limit_reached: 'Maximum product buy limit quantity reached',
    minimum_delivery_amount: 'The minimum order balance has to be greater than 3000Birr for delivery services.',
    choose_discount: 'How do you want to recieve your discount?',
    didnt_choose_discount: 'Please select your preffered method to recieve discount',
    enter_your_pin: 'Enter your pin',
    cbe_step_two: 'After gaining access navigate towards the \"Utilities\" menu. Then select Seregela Gebeya',
    cbe_step_three: 'Enter the order ID displayed below',
    cbe_step_four: 'After completing the payment press the button below (\"finish\") to go home',
    steps_to_follow: "Steps to Follow when inside the CBE mobile banking application",
    steps_tofollow_apollo: "Steps to follow inside the Apollo mobile banking App",
    apollo_step_two: 'After gaining access navigate towards the \"Payments\" menu. Then select Seregela Gebeya',
    confirm_pin: 'Confirm new pin',
    old_pin: 'Old pin',
    new_pin: 'New pin',
    },
am:{
    print: 'ፕሪንት',
    categories:'ምድቦች',
    welcome:'እንኳን በደህና መጡ!',
    buy_now:'አሁኑኑ ይሸምቱ',
    what_are_you_looking_for:'ምን ይፈልጋሉ?',
    register_now:'አሁኑኑ ይመዝገቡ',
    free_transport:'ነጻ ትራንስፖርት',
    loan_service:'የብድር አገልግሎት',
    loan_service_unavailable: "የብድር አገልግሎት ለጊዜው አይሰራም",
    error_fetch_loan: "የብድር ሂሳብ ማምጣት አልተቻለም",
    error_fetch_loan_description: "ይቅርታ ከባንኩ የብድር ሂሳቦን ማምጣት አልቻልንም",
    customer_service:'የደንበኛ አገልግሎት',
    large_number_of_items:'ብዛት ያለው እቃ',
    fair_price:'ተመጣጣኝ ዋጋ',
    free_delivery_in_your_place:'ካሉበት ቦታ በነጻ ትራንስፖርት እናደርሳለን',
    you_can_buy_in_debt:'በብድር መገበይት ይችላሉ',
    guaranted_service_24_7:'24/7 አስተማማኝ ብቁ እገዛ ያገኛሉ',
    large_number_of_items_in_cheap_price:'ብዛት ያላቸው እቃዎች በቅናሽ ዋጋ',
    quality_product_in_low_price:'ጥራቱን የጠበቀ እቃ በቅናስ ዋጋ',
    
    buy_from_choosen_categories:'ከተመረጡ ምድቦቻችን ይገብዩ!',
    best_selling_items:'በጣም የተሸጡ እቃዎች',
    package_items:'የእቃ ጥቅሎች',
    shop_these_packages_with_discount:'እንዚህን ጥቅሎች በቅናሽ ዋጋ ይሸምቱ!',
    subscribe_to_our_news_letter:'ለጋዜጣችን ይመዝገቡ',
    always_be_the_first_to_hear_new_inforation:'ስለሚሸምቱት ዕቃዎች አዲስ መረጃ ለመስማት ሁልግዜም ቀዳሚ ይሁኑ',
    buy:'ይግዙ',
    package:'አስቤዛ',
    items_in_package: 'ጥቅሉ ውስጥ የተካተቱ እካዎች',
    food_and_drink:'ምግብ እና መጠጥ',
    beauty_products:'የውበቶ ዕቃዎች',
    become_a_seller:'ሻጭ ይሁኑ!',
    call_center:'መረጃ ማዕከል',
    ordered_products:'ያዘዙት ዕቃዎች',
    order_history: "የግዢ ታሪክ",
    empty_order_history: "የግዢ ታሪክ የሎትም",
    time: "ሰዕት",
    return_policy:'መመለሻ ፓሊሲ',
    contact_us:'ያነጋግሩን',
    about_seregela:'ስለ ሰረገላ',
    responsibility:'ሀላፊነት',
    vision:'ራዕይ',
    search:'ፍለጋ',
    when_you_buy_monthly_package:'5 የወር አስቤዛ ሲገዙ',
    plus_one:'አንድ ጭማሪ',
    dear_customer_we_delivery_in_your_place:'ውድ ደንበኞቻችን አስቤዛ ካሉበት ቦታ ሆነው ይዘዙን:: በፍጥነት እናደርሳለን 100% ከተፈጥሮ ግብአት የተዘጋጁ ፤ በፈለጉት መጠን እናዘጋጃለን',
    language:'ቋንቋ',
    dont_have_money:'በቂ ገንዘብ የለዎትም፤ አይጫነቁ የብድር አገልግሎታችንን ይመዝገቡ!',
    buy_package_from_one_place:'የቤት አስቤዛዎን ከአንድ ቦታ ሳይንገላቱ ይሸምቱ!',
    available_products:'እቃዎች አግኝተዋል፡፡',
    favourite:"ወደ ተወዳጅ ምርት ይጨምሩ",
    products:'እቃዎች',
    product:'እቃ',
    continue_shopping:'መገብየትዎን ይቀጥሉ!',
    size:'መጠን',
    discount:'ቅናሽ',
    the_price_of_one:'የአንዱ ዋጋ',
    unit_price: "ነጠላ ዋጋ",
    total_price:'ጠቅላላ ዋጋ',
    info:'መረጃ',
    transport:'ትራንስፖርት',
    payment:'ክፍያ',
    continue:'ይቀጥሉ',
    address:'አድራሻ',
    pay_by_seregela_debt:'የሰረገላ የብድር ክፍያ',
    other_payment_option:'ወደ ክፍያ ይሂዱ',
    your_balance:'ያለዎት ሂሳብ',
    your_loan_balance: 'ያለዎት የብድር ሂሳብ',
    payment_option: "የክፍያ መንገድ",
    payment_options:'የክፍያ መንግዶች',
    payment_approval:'ክፍያ ማረጋገጫ',
    payment_failed: 'ክፍያዎት አልተሳካም።',
    retry_payment: 'ክፍያውን እንደገና መሞከር ከፈለጉ እባክዎን ከዚህ በታች ያለውን ቁልፍ ይጫኑ።',
    price_range: "የዋጋ ገደቦች",
    start: "መነሻ ዋጋ",
    end: "መቸረሻ ዋጋ",
    current_location: "አሁን ያሉበት",
    current_location_empty: 'አሁን ያሉበት ቦታ ይጫኑ',
    different_location: "ሌላ ቦታ",
    delivery_cost: 'የማድረሻ ዋጋ',
    payment_method: 'ክፍያ መንግድ',
    order_cancelled: 'ትዕዛዞ ተሰርዞዋል',
    address_registered: 'የአድራሻ ምዝገባ ተሳክቶዋል',
    address_registration_failed: 'አድራሻ አልትመዘገበም',
    loading: 'በመጫን ላይ',
    payment_successfull: "ክፍያዎት ተሳክቷል",
    phone_already_registered: 'በዚ ስልክ ቁጥር ደምበኛ ተመቧል',
    email_already_exists: 'የኢሜል አድራሻው በጥቅም ላይ የዋለ ነው',
    date: 'ቀን',
    status: "ሁኔታ",



    first_string:"አበበ",
    second_string:"ከበደ",
    you_can_change_language_after: "ቋንቋውን ወደ ቅንብሩ ከገቡ በኋላ መቅየር ይችላሉ።",
    anything_you_need_at_your_finger_tips: "የሚያስፈልግዎ ማንኛውም ነገር አጠገብዎ",
    real_discount_from_packages:"እውነተኛ ቅናሽ ከጥቅሎች",
    delivered_to_your_door:"ወደ ደጃፍዎ እናደርሳለን",
    get_started:"ይጀምሩ",
    name:"ስም",
    price:"ዋጋ",
    detail:"ዝርዝር",
    delivery_type:"የማድረስሻ መንገድ",
    delivery_method_empty: "እባክዎ የማድረሻ ምንገድ ይምረጡ",
    order_date:"የታዘዘበት ቀን",
    birr:"ብር",
    product_added:"እቃው ተጨምሯል",
    product_added_description:"ወደ ቅርጫትዎ ተጨምሯል",
    product_removed:"እቃው ተቀንሷል",
    product_removed_description:"ከቅርጫትዎ ተቀንሷል",
    package_added:"ጥቅሉ ተጨምሯል",
    package_added_description:"ወደ ቅርጫትዎ ተጨምሯል",
    package_removed:"ጥቅሉ ተቀንሷል",
    package_removed_description:"ከቅርጫትዎ ተቀንሷል",
    try_again_later:"እንደገና ይሞክሩ",
    something_went_wrong:"የሆነ ስህተት ተፈጥሯል",
    connection_error:"የበይነ-መረብ ግንኙነት ስህተት",
    check_internet_connection:"የበይነ-መረብ ግንኙነት መኖሩን ያረጋግጡ",
    you_ve_logged_out:"ከመተግበሪያው ዋና መጠቀሚያ ወተዋል",
    hope_to_see_you_soon:"በቅርቡ እንደሚመለሱ ተስፋ አናደርጋለን",
    profile_edit_successful:"የመገለጫ ማስተካከያ ተሳክቷል",
    profile_edit_successful_description:"የተጠቃሚ መገለጫዎን በተሳካ ሁኔታ አስተካክለዋል",
    added_to_wish_list:"ወደ ተወዳጅ ዕቃ ዝርዝር አስገብተዋል",
    added_to_wish_list_description:"ወደ ተወዳጅ ዕቃ ዝርዝር አስገብተዋል",
    removed_from_wish_list:"ከተወዳጅ ዕቃ ዝርዝር አስወጥተዋል",
    removed_from_wish_list_description:"ከተወዳጅ ዕቃ ዝርዝር አስወጥተዋል",
    cart:"ዘንቢል",
    no_items_in_cart:"በዘንቢልዎ ውስጥ ምንም እቃዎች የለም",
    no_items_in_cart_description:"ለመግዛት ከፈለጉ እቃዎችን ወደ ዘንቢልዎ ያስገቡ",
    checkout:"ይግዙ",
    checkout_second:"ግዢ",
    sub_total:"ንዑስ ድምር",
    tax:"ታክስ",
    delivery:"ማድረሻ",
    delivery_date: 'ያደረስንበት ቀን',
    next_page:"ቀጣይ ገጽ",
    total:"አጠቃላይ",
    shop_by_suppliers:"በአከፋፋይ ግብይይት",
    no_items_via_this_filter:"በመረጡት ምንም አይነት እቃ የለም። ሌላ ይሞክሩ።",
    no_products:"ምንም እቃ የለም",
    all:"ሁሉም",
    basic_info:"መሰረታዊ መረጃ",
    next:"ቀጣይ",
    convenience:"ምቾት",
    delivered_in_one_week:"በ1 ሳምንት ውስጥ የሚደርስ",
    basic:"መሰረታዊ",
    delivered_in_business_days:"ከ2 እስከ 3 የስራ ቀን የሚደረስ",
    seregela_fast:"ሰረገላ ፈጣን",
    delivered_in_one_day:"በ1 ቀን የሚደርስ",
    credit:"ክሬዲት",
    others:"ሌሎች",
    choose_payment_method:"የክፍያ መንገድዎን ይምረጡ",
    request_more:"ተጨማሪ ይጠይቁ",
    before_purchase:"ከግዢ በፊት",
    after_purchase:"ከግዢ በኋላ",
    finish:"ጨርስ",
    not_corporate:"ከድርጅት አይደሉም",
    not_corporate_description:"የብድር አገልግሎት ለመጠቀም የድርጅት መለያ ሊኖርዎት ይገባል",
    no_loan_balance:"የብድር ቀሪ ሂሳብ የሎትም",
    no_loan_balance_description:"ግዢውን ለመፈጸም ምንም የብድር ቀሪ ሒሳብ የለዎትም",
    insufficient_balance:"በቂ ያልሆነ ገንዘብ",
    insufficient_balance_description:"ቀሪ ሒሳብዎ በቂ አይደለም። የእርስዎ ቀሪ ሒሳብ: ",
    pay_with_credit:"በብድር ይክፈሉ",
    pay_the_difference:"ቀሪውን በሌላ መንገድ ይክፈሉ",
    you_can_pay_with_credit_if_you_have_corporate_id:"የኮርፖሬት አይድ ካልዎት በብድር መክፈል ይችላሉ",
    pay_with_others:"በሌሎች ይክፈሉ",
    telebirr_awashbirr_cbebirr:"ቴሌ ብር , አዋሽ ብር , ሲቢኢ ብር",
    approve_delivery:"ማድረሻ ማረጋገጫ",
    approve_and_pay:"አጽድቀው ይክፈሉ",
    approve:"አጽድቅ",
    cancel:"ሰርዝ",
    product_ordered_successfully:"ትዕዛዝዎ በተሳካ ሁኔታ ተልኳል",
    go_home:"ወደ መጀመሪያ ገጽ",
    go_to_home_page:"ወደ መጀመሪያ ገጽ ይመለሱ?",
    yes:"አዎ",
    no:"አይ",
    edit_profile:"የተጠቃሚ መረጃ ማስተካከያ",
    what_do_you_need:"ምን ይፈልጋሉ?",
    take_your_groceries:"አስቤዛዎን ይውሰዱ",
    pay_later:"ቆይተው ይክፈሉ",
    learn_more:"ተጨማሪ",
    frequently_purchased_items:"በተደጋጋሚ የተገዙ እቃዎች",
    packages:"ጥቅሎች",
    for_you:"ለእርስዎ የተመረጡ",
    more:"ተጨማሪ",
    amharic:"አማርኛ",
    english:"እንግሊዘኛ",
    help_center:"የእርዳታ ማዕከል",
    problem_try_again_later:"የሆነ ችግር ያለ ይመስላል። ቆይተው እንደገና ይሞክሩ።",
    order_list:"የትዕዛዝዎ ዝርዝር",
    orders:"ትዕዛዞች",
    notifications:"ማሳሰቢያዎች",
    settings:"ቅንብሮች",
    change_password:"የሚስጥች ቁጥር መቀየሪያ",
    back:"ወደ ኋላ",
    left_in_stock:"ከመጋዘን የቀረው",
    out_of_stock:"ከመጋዘን አልቋል",
    not_available_in_stock:"ለጊዜው በክምችት ውስጥ አይገኝም",
    add_to_cart:"ወደ ዘንቢል ጨምር",
    description:"ዝርዝር",
    wish_list:"የተወዳጅ ዕቃ ዝርዝር",
    no_items_in_wish_list:"ምንም እቃ ወደ ምኞት ዝርዝር አላስገቡም",
    password_change_successful:"የሚስጥር ቁጥርዎ ተቀሯል",
    password_change_successful_description:"የሚስጥር ቁጥርዎን በተሳካ ሁኔታ ቀየረዋል",
    change:"ቀይር",
    login:"ግባ",
    forgot_password:"የሚስጥር ቁጥርዎን ረስተዋል?",
    new_to_seregela_majet:"ለሰረገላ ገበያ አዲስ ኖት?",
    login_successful:"ወደ ዋና መጠቀሚያው ገብተዋል",
    login_successful_description:"እንኳን ወደ ሰረገላ ገበያ በደህና መጡ!",
    personal_info:"የግል መረጃ",
    this_information_is_needed_for_delivery_service:"ይህ መረጃ ለማድረሻ አገልግሎት ያስፈልጋል",
    register:"ይመዝገቡ",
    already_a_member:"የሰረገላ ገበያ አባል ነዎት?",
    registration_successful:"ምዝገባው ተሳክቷል",
    log_in:"ሎግ ኢን",
    log_out:"ሎግ አውት",
    home:"መግቢያ",
    email_hint:"የኢሜይል አድራሻ",
    email_error:"ትክክለኛ የኢሜይል አድራሻ ያስገቡ (*****@*****.com)",
    password_hint:"የሚስጥር ቁጥር",
    password_empty:"የሚስጥር ቁጥር መሙላት አለብዎ",
    old_password_hint:"የድሮ ሚስጥር ቁጥር",
    old_password_empty:"የድሮ ሚስጥር ቁጥር ማስገባት አለብዎ",
    new_password_hint:"አዲስ ሚስጥር ቁጥር",
    new_password_empty:"አዲስ ሚስጥር ቁጥር ማስገባት አለብዎ",
    password_confirm_hint:"አዲስ ሚስጥር ቁጥር ማረጋገጥ",
    password_confirm_empty:"አዲስ ሚስጥር ቁጥር ማረጋገጫ ማስገባት አለብዎ",
    password_confirm_error:"ከላይ ካስገቡት አዲስ የሚስጥር ቁጥር ጋር አይመሳሰልም",
    user_name_hint:"የተተቃሚ ስም",
    user_name_empty:"የተተቃሚ ስም መሙላት አለብዎ",
    user_name_error:"ምንም አይነት ክፍተት አይጠቀሙ",
    user_name_exist: "የመረጡት የተተቃሚ ስም ተወስዶዋል",
    first_name_hint:"የመጀመሪያ ስም",
    first_name_empty:"የመጀመሪያ ስም መሙላት አለብዎ",
    last_name_hint:"የአባት ስም",
    last_name_empty:"የአባት ስም መሙላት አለብዎ",
    phone_no_hint:"ስልክ ቁጥች",
    phone_no_empty:"የስልክ ቁጥር መሙላት አለብዎ",
    phone_no_error:"ትክክለኛ ስልክ ቁጥር አልሞሉም(2519********)",
    password_length_error:"ስምንት(ከስምንት በላይ) አሃዝ ይስገቡ",
    city_hint:"ከተማ",
    city_empty:"ከተማ መሙላት አለብዎ",
    sub_city_hint:"ክፍለ-ከተማ",
    sub_city_empty:"ክፍለ-ከተማ መሙላት አለብዎ",
    woreda_hint:"ወረዳ",
    woreda_empty:"ወረዳ መሙላት አለብዎ",
    neighborhood_hint:"ሰፈር",
    neighborhood_empty:"ሰፈር መሙላት አለብዎ",
    house_no_hint:"የቤት ቁጥር",
    house_no_empty:"የቤት ቁጥች መሙላት አለብዎ",
    quantity:"ብዛት",
    seregela:"ሰረገላ",
    majet:"ገበያ",
    invalid_phone_number:"ትክክለኛ ስልክ ቁጥር አላስገቡም",
    sorry: "ይቅርታ",
    we_couldnt_find: "የፈለጉትን ገፅ ማግኘት አልቻልንም",
    many_request: "በጣም ብዙ ሎጊን",
    payment_finished_cbebirr: "ስልኮት ላይ የCBE Birr መክፈያ ምልክት ይመታል። ክፍያ ሲቸርሱ እባኮት ከስር ያለውን በመጫን የይቀጥሉ።",
    payment_canceled: "የCBE Birr ግብይቶ ተሰርዞዋል።",
    client_dont_have_cbebirr: "የCBE Birr አካውንት የሎትም።",
    cbebirr_authentication_error: "CBE Birr ማረጋገቻ ስህተት አጋትሟል።",
    hold_your_phone: "ስልኮውን በእጆት የያዙ",
    retry_cbebirr: 'እንደገና ይሞክሩ',
    cbebirr_dormant: 'CBE Birr አካውንቶ ተንቀሳቃሽ አደለም።',
    cbeBirr_limit_reached: 'የCBE Birr እንደገና መሞከር ገደብ ላይ ደርሷል። ቆይተው እንደገና ይሞክሩ',
    enter_code: 'የተላከውን ኮድ አስገባ',
    resend_code: 'እንደገና ላክ',
    terms_and_services: 'ቀጥልን ሲቻኑ በውላችን እና በግላዊነት መመሪያችን ተስማምተዋል።',
    terms_click_to_accept: 'የቡና አስቤዛን ውሎች እና ሁኔታዎች እስማማለሁ።',
    limit_reached: 'ከተገደበው በላይ መግዛት አይችሉም።',
    minimum_delivery_amount: 'የትራንስፖርት አገልግሎት ለማግኘት የትዛዞት ዋጋ 3000ብር በላይ መሆን አለበት።',
    choose_discount: 'ቅናሽዎን እንዴት መቀበል ይፈልጋሉ?',
    didnt_choose_discount: 'እባኮት ቅናሽ መቀበያ ዝዴ ይምረጡ',
    enter_your_pin: 'የሚስጥር ቁጥሮን ያስገብ',
    cbe_step_two: 'ከገቡ ቡሀላ ወደ \"ክፍያዎች\" ያቅኑ። ከዚያ ሰረገላ ገበያ የሚለውን ምርጫ የምረጡ።',
    cbe_step_three: 'ከታች የሚታየውን የትዕዛዝ መታወቂያ ያስገቡ',
    cbe_step_four: 'ክፍያውን ከጨረሱ በኋላ ከታች ያለውን \"ይቅጥሉ\" ሚለውን ይጫኑ ወደ ዋና ገጽ ለመመለስ።',
    steps_to_follow: 'በኢትዮጵያ ንግድ ባንክ የሞባይል ባንክ አፕሊኬሽን ውስጥ ሲሆኑ መከተል ያለባቸው እርምጃዎች',
    steps_tofollow_apollo: "የአፖሎ የስልክ መተግበሪያ መመሪያዎች",
    apollo_step_two: 'ወደ አፖሎ ከገቡ በኋላ \"Payments\" ውስጥ በመግባት ሠረገላ ገባያን ይምረጡ።',
    confirm_pin: 'አዲሱን ፕን ያረጋግጡ',
    old_pin: 'የድሮ ፒን',
    new_pin: 'አዲስ ፕን',
}
}
export {lang};