<template>
    <div class="w-full h-full  relative">
      <HeaderComponent class="z-30" :key="reloadKey"/>
      <router-view @updateUser="updateUser" @updateFooter="footerReload += 1" />
      <FooterComponent class="z-30" :key="footerReload"/>
      <FullWindowPopupView 
        :class="[showAdvert ? 'opacity-100 z-40' : 'opacity-0 -z-10']"
        class="fixed inset-0  transition-opacity ease-in-out"
        :popup="popups[0]"
        @closePopup="() => {showAdvert = false}"
      />
      <!-- <PopupCardVue  
        :class="[showAdvert ? 'translate-y-0 -translate-x-1/2' : 'translate-y-full']" 
        class="xl:w-1/2 lg:w-3/4 w-full fixed md:bottom-0 bottom-10 left-[50%] z-30 transition-transform duration-500 ease-in-out shadow-xl" 
        :popup="popups[0]" 
        @closePopup="()=>{showAdvert = false}" 
      /> -->
    </div>
      
</template>
<script>
import {mapGetters, mapActions} from 'vuex'
import  CategoriesAPI from '@/services/Categories.js'
import ProductAPI from '@/services/Products.js'

import FooterComponent from '@/components/FooterComponent.vue'
import HeaderComponent from '@/components/HeaderComponent.vue'

// import PopupCardVue from '@/components/notification/PopupCard.vue'
import FullWindowPopupView from '@/components/notification/FullWindowPopup.vue'

import './assets/tailwind.css'

export default {
  name: 'HomeView',
  components: {
    FooterComponent,
    HeaderComponent,
    FullWindowPopupView,
  },
  data () {
    return {
        reloadKey: 1,
        footerReload: 2,
        showAdvert: false
    }
  },
  computed:{
    ...mapGetters({
      popups: 'notification/getPopUps',
      adverts: 'notification/getAdverts',
    }),
    route () {
      return this.$store.state.routeName
    },
    login (){
      return this.$store.state.login
    },
    
  },
  methods: {  
    ...mapActions({
      fetchAdverts: 'notification/fetchAdverts',
    }),
    updateUser (value) {
        this.reloadKey = this.reloadKey+value
    }  
  },
  async created (){
     // gets all categories
     if(this.$store.state.categories.length < 1){
       await CategoriesAPI.getCategories()
        .then((response)=>{
          this.$store.commit('setCategories', response)
          this.$emit('updateFooter',1)
        })
     }
     if(this.$store.state.packages.length < 1){
        // gets all the packages in the database
            await ProductAPI.getPackages()
            .then((response)=>{
              this.$store.commit('setPackages', response)
            })
     }
     if(this.$store.state.popularProducts.length < 1){
        // gets all the popular products from the backend
        await ProductAPI.getPopularProducts()
        .then((response)=>{
          this.$store.commit('setPopularProducts', response)
        })
     }   
     if(!(this.adverts.length || this.popups.length)) {
        // fetch the addverts
        const status = await this.fetchAdverts()
        if(status.success && this.popups.length) {
          setTimeout(()=>{
            this.showAdvert = true
          }, 1000)
        } 
     } 
   
  },
  async mounted(){
      this.$store.dispatch('notification/initalizeSocket')
      if(window.localStorage.getItem('token')){     
       this.$store.dispatch('notification/getNotifications')
      }


  }
}
</script>

