/* eslint-disable no-async-promise-executor */
import api from '@/axios.js'

class NotificationAPI {
    static getImageAdvert () {
        return new Promise(async(resolve,reject) =>{
            try {
                const response = await api.get('image-adverts')
                const result = response.data.data
                resolve(result)
            } catch (error) {
                reject(error)
            }
        })
    }
}
export default NotificationAPI