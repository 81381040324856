<template>
    <div class="relative w-full h-full overflow-hidden">
        <carousel-item-vue v-for="(slide,index) in slides"
        :slideImage="slide.image_path" 
        :title="slide.title" 
        :promotion="slide.description"
        :key="`item-${index}`"
        :current-slide="currentSlide"
        :index="index"
        />
</div>
</template>
<script>
import CarouselItemVue from './CarouselItem.vue';
export default {
    props: {
        slides: Object,
    },
    components: {
        CarouselItemVue
    },
    data () {
        return {
            currentSlide: 0,
            slideInterval: null
        }
    },
    methods:{
        setCurrentSlide (index){
            this.currentSlide = index
        }
    },
    mounted() {
        this.slideInterval = setInterval(()=>{
            const index = this.currentSlide < this.slides.length -1? this.currentSlide + 1 : 0
            this.setCurrentSlide(index)
        },5000)
    },
    beforeUnmount () {
        clearInterval(this.slideInterval)
    }
}
</script>