<template>
    <div class="w-full h-auto container bg-white shadow-md  py-5 px-1 flex flex-col justify-center space-y-2 items-center">
        <div class="flex flex-col justify-center items-center">
            <img class="w-14" src="@/assets/images/user.png"/>
            <p class="xl:text-lg lg:text-base md:text-sm text-xs">{{$t("welcome")}}</p>
        </div>      
        
        <span v-if="!user" class="sm:flex justify-around w-full hidden">
            <button @click="goLogin()" class="2xl:px-10 lg:px-3 px-1 2xl:py-2 py-1 2xl:text-lg text-xs btn-primary">{{$t("login")}}</button>
            <button @click="goRegister()" class="2xl:px-9 lg:px-3 px-1 lg:py-2 py-1 text-orangeButton border border-orangeButton bg-white hover:text-white hover:bg-orangeButton hover:border-transparent">{{$t("register_now")}}</button>
        </span>
        <div class="flex flex-col w-full justify-end sideImage">
            <div class="mb-3 ml-4 pr-8 flex flex-col space-y-3">
                <p class="text-white">{{$t("dont_have_money")}}</p>
                <button class="text-white py-3 bg-transparent">{{$t("register_now")}}</button>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    computed:{
        user() {
            if(localStorage.getItem("user")){
                return true
            }else{
                return false
            }
        }
    },
    methods:{
        goRegister(){
            this.$router.push("/profile")
        },
        goLogin() {
            this.$router.push("/login")
        }
    }
}
</script>
<style>
.sideImage  {
    background-image: url('@/assets/images/sideBarImage.png');
    background-repeat: no-repeat;
    height: 100%;
    background-size: cover;
}
</style>