import {createI18n} from 'vue-i18n'
import {lang} from './lang.js'
import store from '../store/index.js'

var lan

// if(localStorage.getItem("lang")=== null){
//   // var stringJson = JSON.stringify({"display": "አማርኛ","identifier": 'am'})
//   var stringJson = JSON.stringify({"display": "English","identifier": 'en'})
//   localStorage.setItem("lang", stringJson)
  
//   // lan = 'am'
//   lan = 'en'
// }else{
//   lan = JSON.parse(localStorage.getItem("lang")).identifier 
// }
lan = store.getters.getLanguage.identifier

const vueI18n =  createI18n({
    legacy: false,
    locale: lan || 'en',
    globalInjection: true,
    messages: {
      en: lang.en,
      am: lang.am,
    }

  })

  export default vueI18n