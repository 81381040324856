<template>
    <div class="flex justify-center items-center bg-white shadow-sm px-3">
       <Carousel :slides="slides"/>
    </div>
</template>
<script>
import Carousel from './CarouselFolder/Carousel.vue'
import {mapGetters} from 'vuex'
export default {
    components: {
        Carousel
    },
    data () {
        return {
            // slides: [],
            existingSlides: [
                {"image_path": "images/Banner-discount.png", "title": "Buy Cleaning products","title_am": "የጽዳት እቃዎችን ይሸምቱ ", "promotion": "Purchase top of the line cleaning products for you and your family at a discounted price","promotion_am": 'ጥራታቸውን የጠበቁ የንጽህና መጠበቂያ እቃዎችን ለእርሶና ለቤትዎ በቅናሽ ዋጋ ይሸምቱ', "buttonInstruction": "Buy now"},
                {"image_path": "images/banner-enrich.png", "title": "Buy Food","title_am":"ምግብ ይግዙ", "promotion": "High quality food and food ingredients at a very low price","promotion_am":"እጅግ በጣም ጥራታቸውን የጠበቁ  ምግቦችና የምግብ ግብአቶችን በጣም በቅናሽ ዋጋ", "buttonInstruction": "Buy now"},
                {"image_path": "images/Banner-discount2.png", "title": "Buy all natural vegetable oils","title_am":"የተፈጥሮ የአትክልት ዘይቶች ይግዙ", "promotion": "100% natural ingredients. No type of artificial flavours added","promotion_am": "100% የተፈጥሮ ንጥረ ነገሮች. ምንም አይነት ሰው ሰራሽ ጣዕም አይጨምርም", "buttonInstruction": "Buy now"},
            ]
        }
    },
    computed: {
        ...mapGetters({
            slides: 'notification/getAdverts',
        })
    },
    mounted () {
       console.log(this.slides)
        
    }
    
}
</script>
<style>
</style>