import router from '@/router'
// import {signInWithPhoneNumber, getAuth,signOut} from 'firebase/auth'
// import firebaseapp from '@/firebase/index.js'
import firebase from 'firebase/app'
import 'firebase/auth'

import UserAPI from '../../services/User.js'

const state = {
    user: {},
    isLoggedIn: false,
    phoneNumber: '',
    count: 0,
    payload: {},
    firebaseToken: '',
    isAuthenticated: false,
    status: false,
    returnRoute : ''
}
const getters = {
    user: (state) => state.user,
    isLoggedIn: (state) => state.isLoggedIn,
    phoneNumber: (state)=> state.phoneNumber,
    counter: (state)=> state.count,
    isAuthenticated: (state)=> state.isAuthenticated
}

const mutations = {
    SETTOKEN: (state,value)=>{
        state.firebaseToken = value
        window.localStorage.setItem("firebaseToken", JSON.stringify(value,null,1))
    },
    SETPAYLOAD: (state,value)=>{
        state.payload =  value
        // var stringJson = JSON.stringify({"display": "አማርኛ","identifier": 'am'})
        // localStorage.setItem("lang", stringJson)
    },
    SETAUTH: (state,auth)=>{
        state.isLoggedIn = true
        state.isAuthenticated = true
       
         localStorage.setItem('token', auth.access_token)
        // state.token = auth.token
    },
    SETUSER: (state, response)=>{
        state.count += 1
        state.user = response.data
        state.isLoggedIn = true
        window.localStorage.setItem("user", JSON.stringify(response.data,null,1))
    },
    recoverTokenFromStorage: (state) => {
        try {
            const token = window.localStorage.getItem('token');
            console.log(token);
            const user = window.localStorage.getItem('profile')
            state.status = token ? true : false;
            state.token = token ? token : null;
            state.user = user ? JSON.parse(user) : null;
        } catch (error) {
            state.status = false
        }
    },
    LOGOUT: (state)=>{
        localStorage.removeItem('token')
        window.localStorage.removeItem('token')
        localStorage.removeItem('cart')
        window.localStorage.removeItem('cart')
        state.isLoggedIn = false
        state.isAuthenticated = false
    }

}

const actions = {
    resend({ dispatch }) {
        let payload = state.payload
        if(!(Object.keys(payload).length === 0)){
            dispatch("sendOTP", payload)
        }else{
            router.replace({
                path: '/login'
            })
        }
    },
    async sendOTP({commit}, val){
        let appVerifier = val.verify
        let cellNumber = val.phone
    
        return await firebase.auth().signInWithPhoneNumber(cellNumber, appVerifier)
            .then((confirmationResult)=>{
                window.confirmationResult = confirmationResult
                
                commit('SETPAYLOAD', val)
                return {'sms-sent': true}
            }).catch((error)=>{
                console.log(error)
                return error
            })
    },
    async verifyAccessToken({commit,state}, value) {
        try {
            let phoneNumber
            if(state.payload.phone[0] === '+'){
                phoneNumber = parseInt(state.payload.phone.substring(1)) 
            }
            await UserAPI.userLogin(phoneNumber,value)
            .then((response)=>{
                commit('SETAUTH', response)
                commit('SETUSER', response)
            })
        } catch (error) {
            console.log(error)
        }
    },
    // user inputs OTP and verifyOtp verifies the otp to firebase
    async verifyOtp({commit,dispatch}, otp){
        return new Promise((resolve)=>{
            let code = otp
            if(!window.confirmationResult == undefined){
                router.replace({
                    path: '/login'
                })
            }else{
                window.confirmationResult.confirm(code)
                    .then(()=>{
                        firebase.auth().currentUser.getIdToken(true)
                            .then((idToken)=>{
                                console.log('the current user token')
                                console.log(idToken)
                                console.log('uuuuuuuuuuuuuuuuu')

                                dispatch("verifyAccessToken", idToken)
                                commit('SETTOKEN', idToken)
                                resolve ({ 'token-pass': true, 'otp': true})
                            })
                            .catch((error)=>{
                                console.log(error)
                                return {'token-pass': false, 'otp': true}
                            })
                        return { 'token-pass': false, 'otp': true}
                    })
                    .catch((error)=>{
                        console.log('bad verification code', error)
                        resolve ({'token-pass': null, 'otp': false})
                    })
            }
        })
        
    },
    async getUserBalance({commit}){
        try {
            await UserAPI.getUser()
                .then((response)=>{
                    commit('SETUSER', response)
                    console.log("user info updated")
                })
        } catch (error) {
            console.log(error)
        }
    },
    async logout({commit}){
        const auth = getAuth();
        signOut(auth).then(() => {
             // Sign-out successful.
            commit('LOGOUT')
        }).catch((error) => {
            console.log(error)
        // An error happened.
        });

    }
}

export default {
    state,
    getters,
    actions,
    mutations,
    namespaced: true
}