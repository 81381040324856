<template>
    <div class="relative w-full py-5 xl:px-5 px-2 headerColor">
        <div class="flex flex-row justify-between">
            <!-- logo -->
            <img v-on:click="goHome()" class="sm:mr-0 mr-2 2xl:w-56 xl:w-48 lg:w-32 md:w-24 sm:w-20 w-14 h-auto cursor-pointer" src="@/assets/images/ser.jpg"/>
            <!-- search bar div  -->
            <div class="flex items-center sm:mr-0 mr-2">
                <div class="relative inline-flex bg-white">
                    <!-- language box -->
                    <div>
                        <button v-on:click="menuClicked = !menuClicked" class="inline-flex items-center xl:py-3 lg:py-2 py-1 xl:px-4 lg:px-2 px-1 2xl:text-lg xl:text-base text-xs border-none bg-white hover:text-blue-500">
                            {{language}}
                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="sm:ml-2 ml-0 xl:w-6 xl:h-6 lg:w-4 lg:h-4 w-3 h-3">
                                <path stroke-linecap="round" stroke-linejoin="round" d="M19.5 8.25l-7.5 7.5-7.5-7.5" />
                            </svg>
                        </button>
                    </div>
                    <div v-if="menuClicked" class="absolute right-0 z-10 sm:mt-2 mt-1 w-full shadow-lg focus:outline-none divide-y divide-gray-500 bg-white flex flex-col">
                        <div v-on:click="showMenu(lang)" v-for="lang in languages" :key="lang" class="py-1 cursor-pointer">
                            <p class="sm:text-base text-xs py-1 px-4 block text-opacity-60">{{lang.display}}</p>
                        </div>
                    </div>
                </div>
                <!-- search bar -->
                <div class="relative flex items-center">
                    <input class="xl:py-3 lg:py-2 py-1 border-b border-white  sm:pr-20 pr-5 placeholder-black placeholder-opacity-75 2xl:text-lg xl:text-base text-xs w-full" type="text" :placeholder="searchPlaceholder" v-model="searchString" @input="search()" />
                    <svg v-if="showClose" @click="close()" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="absolute cursor-pointer right-1 w-6 h-6">
                        <path stroke-linecap="round" stroke-linejoin="round" d="M6 18L18 6M6 6l12 12" />
                    </svg>

                </div>
                <button class="searchButtonColor flex sm:space-x-2 space-x-1 xl:py-3 lg:py-2 py-1 sm:px-5 px-2 border border-white text-white hover:text-blue-600 hover:bg-white items-center">
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" class="xl:w-6 xl:h-6 w-4 h-4">
                        <path fill-rule="evenodd" d="M10.5 3.75a6.75 6.75 0 100 13.5 6.75 6.75 0 000-13.5zM2.25 10.5a8.25 8.25 0 1114.59 5.28l4.69 4.69a.75.75 0 11-1.06 1.06l-4.69-4.69A8.25 8.25 0 012.25 10.5z" clip-rule="evenodd" />
                    </svg>
                    <p class="sm:block hidden text-left 2xl:text-lg xl:text-base text-xs">{{$t("search")}}</p>
                </button>
            </div>
            <!-- profile, cart and logout button  -->
            <div class="flex items-center sm:space-x-10 space-x-2">
                <div v-if="userPresent" class="relative">
                   <svg @mouseover="accountDetails = true" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="white" class="2xl:w-10 2xl:h-10 xl:w-8 xl:h-8  lg:w-6 lg:h-6 w-4 h-4 cursor-pointer">
                         <path fill-rule="evenodd" d="M18.685 19.097A9.723 9.723 0 0021.75 12c0-5.385-4.365-9.75-9.75-9.75S2.25 6.615 2.25 12a9.723 9.723 0 003.065 7.097A9.716 9.716 0 0012 21.75a9.716 9.716 0 006.685-2.653zm-12.54-1.285A7.486 7.486 0 0112 15a7.486 7.486 0 015.855 2.812A8.224 8.224 0 0112 20.25a8.224 8.224 0 01-5.855-2.438zM15.75 9a3.75 3.75 0 11-7.5 0 3.75 3.75 0 017.5 0z" clip-rule="evenodd" />
                    </svg> 
                    <div v-click-outside="clickAway" v-show="accountDetails" @mouseover="accountDetails=true" @mouseleave="accountDetails = false"  class="absolute z-20 right-0 px-4 py-2 mt-1 flex flex-col justify-center items-center space-y-2 bg-white rounded">
                        <div class="flex space-x-1">
                            <img v-if="userInfo.profiile_thumbnail_path" class="w-1/3" :src="userInfo.profile_thumbnail_path" />
                            <img v-else class="w-1/2" src="@/assets/images/user.png"/>
                            <svg @click="$router.push('/edit')" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-4 h-4 cursor-pointer">
                                <path stroke-linecap="round" stroke-linejoin="round" d="M16.862 4.487l1.687-1.688a1.875 1.875 0 112.652 2.652L10.582 16.07a4.5 4.5 0 01-1.897 1.13L6 18l.8-2.685a4.5 4.5 0 011.13-1.897l8.932-8.931zm0 0L19.5 7.125M18 14v4.75A2.25 2.25 0 0115.75 21H5.25A2.25 2.25 0 013 18.75V8.25A2.25 2.25 0 015.25 6H10" />
                            </svg>
                        </div>
                        <p>{{userInfo.first_name}}</p>
                        <span class="flex justify-start items-center w-full">
                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="text-orangeButton w-4 h-4">
                                <path stroke-linecap="round" stroke-linejoin="round" d="M2.25 6.75c0 8.284 6.716 15 15 15h2.25a2.25 2.25 0 002.25-2.25v-1.372c0-.516-.351-.966-.852-1.091l-4.423-1.106c-.44-.11-.902.055-1.173.417l-.97 1.293c-.282.376-.769.542-1.21.38a12.035 12.035 0 01-7.143-7.143c-.162-.441.004-.928.38-1.21l1.293-.97c.363-.271.527-.734.417-1.173L6.963 3.102a1.125 1.125 0 00-1.091-.852H4.5A2.25 2.25 0 002.25 4.5v2.25z" />
                            </svg>
                            <p class="ml-1">{{userInfo.phone_number}}</p>
                        </span>
                        <span v-if="userInfo.email" class="flex justify-start items-center w-full">
                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="text-orangeButton w-4 h-4">
                                <path stroke-linecap="round" d="M16.5 12a4.5 4.5 0 11-9 0 4.5 4.5 0 019 0zm0 0c0 1.657 1.007 3 2.25 3S21 13.657 21 12a9 9 0 10-2.636 6.364M16.5 12V8.25" />
                            </svg>
                            <p class="ml-1">{{userInfo.email}}</p>
                        </span>
                        <span class="flex justify-start items-center w-full">
                            <svg xmlns="http://www.w3.org/2000/svg" width="1.2em" height="1.2em" viewBox="0 0 24 24"><g fill="none" stroke="orange" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5"><path d="M21 12V7H5a2 2 0 0 1 0-4h14v4"/><path d="M3 5v14a2 2 0 0 0 2 2h16v-5"/><path d="M18 12a2 2 0 0 0 0 4h4v-4Z"/></g></svg>
                            <p class="ml-1">ETB {{ userInfo.wallet_balance }}</p>
                        </span>
                        <span v-if="userInfo.bank" class="flex justify-start items-center w-full">
                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="text-orangeButton w-4 h-4">
                                <path stroke-linecap="round" stroke-linejoin="round" d="M2.25 18.75a60.07 60.07 0 0115.797 2.101c.727.198 1.453-.342 1.453-1.096V18.75M3.75 4.5v.75A.75.75 0 013 6h-.75m0 0v-.375c0-.621.504-1.125 1.125-1.125H20.25M2.25 6v9m18-10.5v.75c0 .414.336.75.75.75h.75m-1.5-1.5h.375c.621 0 1.125.504 1.125 1.125v9.75c0 .621-.504 1.125-1.125 1.125h-.375m1.5-1.5H21a.75.75 0 00-.75.75v.75m0 0H3.75m0 0h-.375a1.125 1.125 0 01-1.125-1.125V15m1.5 1.5v-.75A.75.75 0 003 15h-.75M15 10.5a3 3 0 11-6 0 3 3 0 016 0zm3 0h.008v.008H18V10.5zm-12 0h.008v.008H6V10.5z" />
                            </svg>
                            <p v-if="userInfo.loan_balance" class="ml-1">{{userInfo.loan_balance}}{{$t("birr")}}</p>
                            <p v-else class="ml-1">{{$t("error_fetch_loan") }}</p>
                        </span>
                        <span @click="$router.push('/history')" class="flex justify-start items-center w-full cursor-pointer">
                            <svg xmlns="http://www.w3.org/2000/svg" width="1.2em" height="1.2em" preserveAspectRatio="xMidYMid meet" viewBox="0 0 24 24"><path fill="orange" d="M13 3a9 9 0 0 0-9 9H1l3.89 3.89l.07.14L9 12H6c0-3.87 3.13-7 7-7s7 3.13 7 7s-3.13 7-7 7c-1.93 0-3.68-.79-4.94-2.06l-1.42 1.42A8.954 8.954 0 0 0 13 21a9 9 0 0 0 0-18zm-1 5v5l4.28 2.54l.72-1.21l-3.5-2.08V8H12z"/>
                            </svg>
                            <p class="ml-1 hover:text-orangeButton">{{$t("order_history")}}</p>
                        </span>
                        <span v-on:click="logout()" class="flex justify-start items-center w-full cursor-pointer">
                            <svg width="1.2em" height="1.2em" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="orange" >
                                <path fill-rule="evenodd" d="M7.5 3.75A1.5 1.5 0 006 5.25v13.5a1.5 1.5 0 001.5 1.5h6a1.5 1.5 0 001.5-1.5V15a.75.75 0 011.5 0v3.75a3 3 0 01-3 3h-6a3 3 0 01-3-3V5.25a3 3 0 013-3h6a3 3 0 013 3V9A.75.75 0 0115 9V5.25a1.5 1.5 0 00-1.5-1.5h-6zm10.72 4.72a.75.75 0 011.06 0l3 3a.75.75 0 010 1.06l-3 3a.75.75 0 11-1.06-1.06l1.72-1.72H9a.75.75 0 010-1.5h10.94l-1.72-1.72a.75.75 0 010-1.06z" clip-rule="evenodd" />
                            </svg>
                            <p class="ml-1 hover:text-orangeButton">{{ $t("log_out") }}</p>
                        </span>
                    </div>
                </div>
                <div v-if="cartPresent || userPresent" v-on:click="goToCart()" class="relative sm:block inline-flex hidden cursor-pointer">
                    <!-- cart svg -->
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="white" class="2xl:w-10 2xl:h-10 xl:w-8 xl:h-8  lg:w-6 lg:h-6 w-4 h-4">
                        <path d="M2.25 2.25a.75.75 0 000 1.5h1.386c.17 0 .318.114.362.278l2.558 9.592a3.752 3.752 0 00-2.806 3.63c0 .414.336.75.75.75h15.75a.75.75 0 000-1.5H5.378A2.25 2.25 0 017.5 15h11.218a.75.75 0 00.674-.421 60.358 60.358 0 002.96-7.228.75.75 0 00-.525-.965A60.864 60.864 0 005.68 4.509l-.232-.867A1.875 1.875 0 003.636 2.25H2.25zM3.75 20.25a1.5 1.5 0 113 0 1.5 1.5 0 01-3 0zM16.5 20.25a1.5 1.5 0 113 0 1.5 1.5 0 01-3 0z" />
                    </svg>
                    <span class="absolute text-base -top-2 -right-3 z-10 w-4 h-4 p-1 text-white bg-red-600 rounded-full border-none flex justify-center items-center" v-if="cartCount != 0">{{cartCount}}</span>
                </div>
                <svg v-if="isFavorite"  v-on:click="goFavorite()" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" class="2xl:w-10 2xl:h-10 xl:w-8 xl:h-8  lg:w-6 lg:h-6 w-4 h-4 cursor-pointer text-white">
                        <path d="M11.645 20.91l-.007-.003-.022-.012a15.247 15.247 0 01-.383-.218 25.18 25.18 0 01-4.244-3.17C4.688 15.36 2.25 12.174 2.25 8.25 2.25 5.322 4.714 3 7.688 3A5.5 5.5 0 0112 5.052 5.5 5.5 0 0116.313 3c2.973 0 5.437 2.322 5.437 5.25 0 3.925-2.438 7.111-4.739 9.256a25.175 25.175 0 01-4.244 3.17 15.247 15.247 0 01-.383.219l-.022.012-.007.004-.003.001a.752.752 0 01-.704 0l-.003-.001z" />
                    </svg>
                <div @click="openNotification" v-if="notifications.length > 0" class="relative cursor-pointer">
                    <!-- bell svg -->
                    <svg class="2xl:w-10 2xl:h-10 xl:w-8 xl:h-8  lg:w-6 lg:h-6 w-4 h-4 text-white cursor-pointer" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 36 36"><path fill="currentColor" d="m32.85 28.13l-.34-.3A14.37 14.37 0 0 1 30 24.9a12.63 12.63 0 0 1-1.35-4.81v-4.94A10.81 10.81 0 0 0 19.21 4.4V3.11a1.33 1.33 0 1 0-2.67 0v1.31a10.81 10.81 0 0 0-9.33 10.73v4.94a12.63 12.63 0 0 1-1.35 4.81a14.4 14.4 0 0 1-2.47 2.93l-.34.3v2.82h29.8Z" class="clr-i-solid clr-i-solid-path-1"/><path fill="currentColor" d="M15.32 32a2.65 2.65 0 0 0 5.25 0Z" class="clr-i-solid clr-i-solid-path-2"/><path fill="none" d="M0 0h36v36H0z"/></svg>
                    <span v-if="newNotification" class="absolute text-base -top-2 -right-1 z-10 w-4 h-4 p-1 text-red-600 rounded-full bg-red-600 border-none flex justify-center items-center">1</span>
                    <div class="absolute md:top-10 top-5 right-3 p-1 flex flex-col divide-y bg-slate-50 rounded-b-md rounded-tl-md md:w-[20vw] w-[70vw] h-screen overflow-y-scroll orangeScrollbar" v-if="openNotifications">
                        <span class="" v-for="notification in notifications.slice(0,100)" :key="notification">
                            <!-- alter_type is created in the notifications file
                            if alter_type is 1 then this notifications are fetched from the backend -->
                            <div v-if="notification.alter_type == 1">
                                <span class="py-3 px-2 flex flex-col" v-if="notification.type.includes('CompanyPushNotification')">
                                    <p class="self-end text-xs text-orangeButton font-light">Message</p>
                                    <p class="font-semibold truncate">{{ notification.data.title }}</p>
                                    <p class="text-grey-400 text-sm">{{ notification.data.message }}</p>
                                    <p class="self-end mt-2 -mb-2 text-black/70 text-xs font-light">{{ timeCal(notification.created_at) }}</p>
                                </span>
                                <span class="py-3 px-2 flex flex-col bg-cyan-500/40" v-if="notification.type.includes('OrderPickedUpNotification')">
                                    <p class="self-end text-xs font-light">Pick-up</p>
                                    <p class="font-semibold">Your order has been picked up</p>
                                    <span class="mt-1 flex justify-between">
                                        <p class="text-grey-400 text-sm">OTP: <span class="inline text-lg font-medium text-red-500">{{ notification.data.otp }}</span></p>
                                        <p class="text-grey-400 text-sm">Order id: {{ notification.data.order_id }}</p>
                                    </span>
                                    <p class="self-end mt-2 -mb-2 text-black/70 text-xs font-light">{{ timeCal(notification.created_at) }}</p>
                                </span>
                                <span class="py-3 px-2 flex flex-col bg-lime-400/40" v-if="notification.type.includes('OrderPaidNotification')">
                                    <p class="self-end text-xs font-light">Order Paid</p>
                                    <p class="font-semibold">Payment for your order has been processed</p>
                                    <span class="mt-1 flex justify-between">
                                        <p class="text-grey-400 text-sm">Order id: {{ notification.data.order_id }}</p>
                                        <p class="text-grey-400 text-sm"> {{ notification.data.amount }} {{ $t('birr') }}</p>
                                    </span>
                                    <p class="self-end mt-2 -mb-2 text-black/70 text-xs font-light">{{ timeCal(notification.created_at) }}</p>
                                </span>
                            </div>
                            <!-- if alter_type is 3 then they are public notifications -->
                            <span class="py-3 px-2 flex flex-col" v-else-if="notification.alter_type == 3">
                                <p class="self-end text-xs text-pink-500 font-light">Notification</p>
                                <p class="font-semibold truncate">{{ notification.advert.title }}</p>
                                <p class="text-grey-400 text-sm">{{ notification.advert.message }}</p>
                                <p class="self-end mt-2 -mb-2 text-black/70 text-xs font-light">{{ timeCal(notification.created_at) }}</p>
                            </span>
                            <!-- if alter_type is 2 then they are private notifications -->
                            <span  class="py-3 px-2 flex flex-col" v-else-if="notification.alter_type == 2">
                                <p class="self-end text-xs text-pink-500 font-light">Notification</p>
                                <p class="font-semibold truncate">{{ notification.title }}</p>
                                <p class="text-grey-400 text-sm">{{ notification.message }}</p>
                                <p class="self-end mt-2 -mb-2 text-black/70 text-xs font-light">{{ timeCal(notification.created_at) }}</p>
                            </span>
                        </span>
                    </div>
                </div>
                 <!-- login button appears if no user profile detected -->
                <div class="self-center" v-if="!userPresent">
                    <button @click="login()" class="bg-transparent sm:border-2 border border-white text-white sm:py-3 py-1 sm:px-8 px-3 sm:text-base text-sm">{{$t("login")}}</button>
                </div>
            </div>
           
        </div>
           
        <!-- search window appear when user initiate search -->
        <div v-if="showClose" class="fixed pb-5 bg-black/90 flex flex-col sm:space-y-3 space-y-1 justify-start items-center w-full h-screen z-30 md:top-36 top-16 right-0 overflow-y-auto">
            <div 
            @click="goProducts(result.id,result.name)"
            class="sm:w-1/2 w-4/5 border-b flex justify-between items-center hover:shadow-xl hover:translate-x-1 cursor-pointer bg-white z-30" 
            v-for="result in searchResult" :key="result">
                <span class="w-2/4 flex space-x-2 items-start">
                    <img class="w-1/2" :src="result.image_paths[0]"/>
                    <p class="sm:text-4xl text-lg font-extrabold">{{result.name}}</p>
                </span>
                <span class="w-1/4">
                    <p class="sm:text-xl text-sm font-semibold">{{result.price}}{{$t("birr")}}</p>
                </span>
            </div>
            <div v-if="searchResult.length == 0" class="bg-white w-full h-full inset-20 flex justify-center items-center">
                <p>{{$t("no_items_via_this_filter")}}</p>
            </div>
        </div>
    </div>
</template>
<script>
import ProductAPI from '@/services/Products.js'
// time calculator
import TimeCal from "@/components/timeCal";
export default {
    data () {
        return {
            // language: JSON.parse(localStorage.getItem("lang")).display,
            menuClicked: false,
            languages: [{ 'display':'English', 'identifier': 'en'},{'display':'አማርኛ','identifier': 'am'}],
            openNotifications: false,
            searchString: '',
            accountDetails: false,
            showClose: false,
            searchPlaceholder: this.$t("what_are_you_looking_for"),
            userInfo: JSON.parse(localStorage.getItem("user"))
        }
    },
    computed:{
        notifications(){
            return this.$store.getters['notification/bringNotifications']
        },
        newNotification(){
            return this.$store.getters['notification/newNotification']
        },
        cartPresent(){
            return this.$store.getters.getCart
        },
        cartCount(){
            return this.$store.getters.getCart.length           
        },
        searchResult () {
            return this.$store.state.searchResult
        },
        isFavorite () {
            return this.$store.getters.getFavorites.length
        },
        language () {
            return this.$store.getters.getLanguage.display
        },
        userPresent(){
            if(window.localStorage.getItem("user")){
                return true
            }else{
                return false
            }
        }
    },
    async mounted(){      
    },
    methods:{
        clickAway(){
            this.accountDetails = false
        },
        timeCal(date){
            return TimeCal(date)
        },
        openNotification(){
            this.openNotifications = !this.openNotifications
            this.$store.commit('notification/RESETNEWNOTIFICATION')
        },
        showMenu(lang) {
            this.menuClicked =!this.menuClicked
            this.$store.commit('setLanguage',{"display": lang.display, "identifier":lang.identifier} )
            this.$i18n.locale = lang.identifier
            // localStorage.setItem('lang',  JSON.stringify({"display": lang.display, "identifier":lang.identifier}))
            // window.location.reload()
            // this.language = lang.display
        },
        goHome(){
            this.$router.push('/')
        },
        logout () {
            window.localStorage.removeItem("firebaseToken")
            localStorage.removeItem("user")
            this.$store.commit('auth/LOGOUT')
            setTimeout(()=>{
                this.$router.push('/')
                window.location.reload()
                this.$toast.warning(this.$t("you_ve_logged_out"))
            }, 1500)
            
            
        },
        login(){
            this.$router.push('/login')
        },
        goToCart () {
            this.$router.push('/cart')
        },
        goFavorite(){
            this.$router.push('/favorites')
        },
        async search(){
            this.showClose = true
            await ProductAPI.searchProducts(this.searchString)
            .then((response)=>{
                this.$store.commit('setSearchResult', response)
            })
        },
        close(){
            this.searchString = ''
            this.showClose = false
            this.$store.state.searchResult = []            
        },
        goProducts(id,name) {
            this.$store.commit('setProductId', id)
            this.$router.push({ name: 'Product', params: { PID: name } })
            this.close()
        }
    },
}
</script>
<style>
.headerColor{
    background-color: #AE7F2D;
}
.searchButtonColor{
    background-color: #EE8F35;
}
</style>