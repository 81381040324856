<template>
    <div class="sm:px-16 px-2 w-full h-full">
        <div class="h-full">
            <div class="h-full flex flex-col space-y-4">
                <div v-if="loading == 0" class="inset-0 h-1/2 flex justify-center items-center bg-white">
                    <span class="xl:text-4xl lg:text-2xl md:text-xl sm:text-lg text-base text-orangeButton">{{$t('loading')}}</span>
                </div>
                <div v-else-if="loading == 1" class="h-1/2 flex justify-between">
                    <div 
                    @click="deliveryChoice = deliveryType.id"
                    v-for="(deliveryType, index) in deliveryTypes" :key="index"
                    class="w-1/4 cursor-pointer flex flex-col rounded-t-lg shadow-lg transition ease-in hover:scale-110 hover:shadow-lg"
                    :class="[deliveryType.id === deliveryChoice ? 'grayscale-0 scale-110': 'grayscale scale-100']">
                        <div class="sm:h-1/3 h-1/4 z-10 shadow-sm bg-orangeButton text-white flex justify-center items-center">
                            <p v-if="language === 'en'">{{deliveryType.name}}</p>
                            <p v-else>{{deliveryType.name_am}}</p>
                        </div> 
                        <div class="sm:h-2/3 h-3/4 flex flex-col bg-white text-black text-opacity-70">                            
                            <p class="sm:text-base text-xs" v-if="language === 'en'">{{deliveryType.description}}</p>
                            <p class="sm:text-base text-xs" v-else>{{deliveryType.description_am}}</p>
                            <span v-show="deliveryType.delivery_cost_ranges.length > 0">
                                <hr />
                                <p class="sm:text-base text-xs mt-2">{{$t("price_range")}}</p>
                                <span  v-for="ranges in deliveryType.delivery_cost_ranges" :key="ranges.id">
                                    <span class="flex justify-between items-center sm:mt-2 mt-0">
                                        <p  class="xl:text-base sm:text-sm text-xs">{{ranges.start}} - {{ranges.end}}</p>
                                        <p class="xl:text-base sm:text-sm text-xs font-semibold">+{{ranges.cost_percentage}}%</p>
                                    </span>
                                </span>
                            </span>
                        </div>
                    </div>
                
                </div>
                <div v-else class="inset-0 h-1/2 flex flex-col justify-center items-center bg-white">
                    <span class="lg:text-2xl md:text-xl sm:text-lg text-base text-orangeButton text-center px-5">{{$t('minimum_delivery_amount')}}</span>
                    <button @click="$router.replace('/')" class="mt-4 text-orangeButton underline">{{ $t('go_home') }}</button>
                </div>
                <div class="md:h-1/6 h-auto flex lg:flex-row flex-col justify-between w-full">
                    <button 
                    @click="getCurrentLocation()" 
                    class="flex justify-around items-center md:w-1/5 w-1/3 py-3 bg-white hover:shadow-md hover:scale-105">
                        <!-- location svg -->
                        <svg :class="[locationClicked ? 'text-orangeButton': 'text-black']" xmlns="http://www.w3.org/2000/svg" width="48px" height="48px" preserveAspectRatio="xMidYMid meet" viewBox="0 0 24 24"><circle cx="12" cy="12" r="4" fill="currentColor"/><path fill="currentColor" d="M13 4.069V2h-2v2.069A8.01 8.01 0 0 0 4.069 11H2v2h2.069A8.008 8.008 0 0 0 11 19.931V22h2v-2.069A8.007 8.007 0 0 0 19.931 13H22v-2h-2.069A8.008 8.008 0 0 0 13 4.069zM12 18c-3.309 0-6-2.691-6-6s2.691-6 6-6s6 2.691 6 6s-2.691 6-6 6z"/></svg>
                        <p class="font-medium sm:text-2xl text-lg">{{$t("current_location")}}</p>
                    </button>
                    <p> -- and --</p>
                    <div class="grid sm:grid-cols-2 grid-cols-1 grid-flow-row gap-x-4 gap-y-3">
                        <p class="col-span-2 sm:text-center text-left text-lg font-semibold">{{$t("different_location")}}</p>
                        <input v-show="('city' in userPresent.address &&  userPresent.address.city !== undefined)"  :disabled="disableInput" :class="{'cursor-not-allowed': disableInput}" class="py-1 pl-2 text-sm w-full" type="text" :placeholder="cityHint" v-model="customLocation.city" />
                        <input v-show="'sub_city' in userPresent.address" :disabled="disableInput" :class="{'cursor-not-allowed': disableInput}" class="py-1 pl-2 text-sm w-full" type="text" :placeholder="subCityHint" v-model="customLocation.sub_city" />
                        <input v-show="!userPresent.address.woreda" :disabled="disableInput" :class="{'cursor-not-allowed': disableInput}" class="py-1 pl-2 text-sm w-full" type="text" :placeholder="woredaHint" v-model="customLocation.woreda" />
                        <input :class="{'border-red-500' : !customLocation.neighborhood }" class="py-1 pl-2 text-sm w-full" type="text" :placeholder="neighborhoodHint" v-model="customLocation.neighborhood" />
                        <input v-show="!userPresent.address.house_number" :disabled="disableInput" :class="{'cursor-not-allowed': disableInput}" class="py-1 pl-2 text-sm w-full" type="text" :placeholder="houseNumberHint" v-model="customLocation.house_number" />
                    </div>
                    <button @click="deliveryChoices()" class="z-30 self-end sm:mt-0 mt-2 text-white bg-orangeButton sm:py-3 py-1 sm:w-1/6 w-full hover:bg-white hover:border hover:border-orangeButton hover:text-orangeButton">{{$t("next")}}</button>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import OrderAPI from '@/services/Orders.js'
export default {
    data () {
        return {
            deliveryChoice: 0,  
            locationClicked: false,       
            deliveryTypes: [],
            unsortedDeliveryTypes: [],
            disable: true,
            disableInput: false,
            loading: 0,
            err: '',
            locationPresent: false,
            neighborhoodPresent: false,
            cityHint: this.$t("city_hint"),
            subCityHint: this.$t("sub_city_hint"),
            woredaHint: this.$t("woreda_hint"),
            neighborhoodHint: this.$t("neighborhood_hint"),
            houseNumberHint: this.$t("house_no_hint"),
            // language: JSON.parse(localStorage.getItem("lang")).identifier,
            location: {
                latitude: null,
                longitude: null
            },
            customLocation: {
                city: '',
                woreda: '',
                sub_city:  '',
                neighborhood: '',
                house_number: ''
            }
        }
    },
    methods:{
        getCurrentLocation(){
            this.locationClicked = true
            if(navigator.geolocation){
                navigator.geolocation.getCurrentPosition(pos =>{
                    this.location.latitude = pos.coords.latitude
                    this.location.longitude = pos.coords.longitude
                   
                    this.$toast.success("Location registered")
                }, err => {
                    this.err = err.message
                    this.$toast.error(this.err)
                })
            }else{
                this.err = "Geo location is not supportedby this browser"
                this.$toast.error(this.err)
                this.locationClicked = false
            } 
            this.disableInput = true  
        },
        deliveryChoices(){
            if(this.location.latitude && this.customLocation.neighborhood.length>0 && this.deliveryChoice != 0){
                this.$emit('updateDelivery', {
                    "delivery_type_id": this.deliveryChoice,
                    "latitude": this.location.latitude, 
                    "longitude": this.location.longitude, 
                    "neighborhood": this.customLocation.neighborhood
                })
                this.$router.push("/checkout/payment")
            }else{
                
                if(!this.location.latitude){
                    if(!this.locationClicked){
                        this.$toast.warning(this.$t("current_location_empty"))
                    }else{
                         this.getCurrentLocation()
                    }
                   
                }
                if(this.customLocation.neighborhood.length < 4){
                    this.$toast.warning(this.$t("neighborhood_empty"))
                }if(!this.deliveryChoice){
                    this.$toast.warning(this.$t("delivery_method_empty"))
                }
            }
            
        }, 
        async sortDeliveryTypes() {
            for(var i = 0, l =  this.unsortedDeliveryTypes.length; i< l; i++){
                if(!this.unsortedDeliveryTypes[i].minimum_order_cost){
                    this.deliveryTypes.push(this.unsortedDeliveryTypes[i])
                }
                else if(this.unsortedDeliveryTypes[i].minimum_order_cost <= this.itemTotal.totalBill){
                     this.deliveryTypes.push(this.unsortedDeliveryTypes[i])
                }else{
                    // do nothing
                }
            }
            if(this.deliveryTypes.length < 1){
                this.loading = 2
            }else{
                this.loading = 1
            }
            
        }
    },
    computed:{
        itemTotal () {
            return this.$store.getters.totalPrice
        },
        userPresent() {
            return JSON.parse(localStorage.getItem("user"))
        },
        language () {
            return this.$store.getters.getLanguage.identifier
        }
    },
    watch:{
       "customLocation.neighborhood" (newValue,oldValue){
            if(newValue.length > oldValue.length){
                this.neighborhoodPresent = true
            }
       },
       neighborhoodPresent(value){
            if(value){
                this.disable = false
            }
       }
    },
    async created () {
        this.loading = 0
        await OrderAPI.getDeliveryTypes()
            .then((response)=>{
                this.unsortedDeliveryTypes = response
                this.sortDeliveryTypes()
                
            })
    },
}
</script>