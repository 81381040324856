<template>
    <div class="w-full py-16 xl:px-5 px-2 bg-black">
        <div class="grid xl:grid-cols-6 grid-cols-2 grid-flow-row gap-8 content-center">
            <!-- first grid -->
            <div class="flex flex-col col-span-2 w-full">
                <p class="md:text-2xl text-base text-white text-left">{{$t("subscribe_to_our_news_letter")}}</p>
                <p class="mt-5 md:text-base text-sm text-white text-left">{{$t("always_be_the_first_to_hear_new_inforation")}}</p>
                <span class="flex mt-2"> 
                    <input class="text-xl py-3 pl-3 pr-9 bg-white text-black border-none" type="text"/>
                    <button class="md:py-3 md:px-6 py-2 px-2 border-r border-b border-t rounded-r-lg border-blue-600 bg-black text-white hover:text-blue-600 hover:bg-white">{{$t("register")}}</button>
                </span>               
            </div>
            <!-- second grid  -->
            <div class="flex flex-col">
                <a class="cursor-pointer" href="https://play.google.com/store/apps/details?id=com.seregela.majet&hl=en&gl=US" target="_blank" ><img class="cursor-pointer" src="@/assets/images/googlePlay.png"/></a>
                <a class="cursor-pointer" href="https://www.apple.com/us/search/seregela-gebeya?src=globalnav" target="_blank"><img class="mt-6" src="@/assets/images/appStore.png"/></a>
            </div>
            <div class="flex flex-col text-white space-y-2">
                <p class="md:text-2xl text-base text-left">{{$t("buy")}}</p>
                <hr class="w-full border border-white"/>
                <span @click="categoryClicked(category.id,category.name)" class="md:text-base text-sm hover:text-yellow-600 hover:underline cursor-pointer"  v-for="category in categories" :key="category"><p  v-if="language === 'en'">{{category.name}}</p><p v-else>{{category.name_am}}</p></span>
            </div>
            <!-- Third grid  -->
            <div class="flex flex-col text-white space-y-2">
                <p class="md:text-2xl text-base text-left">{{$t("become_a_seller")}}</p>
                <hr class="w-full border border-white"/>
                <p class=" md:text-base text-sm hover:text-yellow-600 hover:underline cursor-pointer ">{{$t("call_center")}}</p>
                <p class="md:text-base text-sm hover:text-yellow-600 hover:underline cursor-pointer ">{{$t("ordered_products")}}</p>
                <p class=" md:text-base text-sm hover:text-yellow-600 hover:underline cursor-pointer ">{{$t("return_policy")}}</p>
                <p class="md:text-base text-sm hover:text-yellow-600 hover:underline cursor-pointer ">{{$t("contact_us")}}</p>
            </div>
            <!-- fourth grid  -->
            <div class="flex flex-col text-white space-y-2">
                <p class="md:text-2xl text-base text-left">{{$t("seregela")}}</p>
                <hr class="w-full border border-white"/>
                <p class="md:text-base text-sm hover:text-yellow-600 hover:underline cursor-pointer ">{{$t("about_seregela")}}</p>
                <p class="md:text-base text-sm hover:text-yellow-600 hover:underline cursor-pointer ">{{$t("responsibility")}}</p>
                <p class="md:text-base text-sm hover:text-yellow-600 hover:underline cursor-pointer ">{{$t("vision")}}</p>
            </div>
            
        </div>
    </div>
    <p class="text-center text-white/70 text-lg w-full bg-black py-2">Powered by <a href="https://zowitech.com/" target="_blank">Zowitech</a> </p>
</template>
<script>
export default {
    computed:{
        categories(){
            return this.$store.getters.getCategories 
        },
        language () {
            return this.$store.getters.getLanguage.identifier
        }
    },
    methods: {
        categoryClicked(catID, name){
            this.$store.commit('setCategoryID', catID)
            this.$router.push({name:'Categories', params: {catID: name }})
        }
    }
}
</script>