<template>
    <div class="h-full md:w-full w-full ">
        <span class="text-3xl cursor-pointer mx-2 md:hidden inline absolute top-1 z-10" v-on:click="menu()"
        :class="[menuOpen ? 'right-2' : 'sm:left-8 left-2']"
        >
            <svg name="menuIcon" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="black" class="inline w-10 h-10 cursor-pointer" v-if="!menuOpen">
              <path stroke-linecap="round" stroke-linejoin="round" d="M3.75 6.75h16.5M3.75 12h16.5m-16.5 5.25h16.5" />
            </svg>
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="black" class="inline cursor-pointer w-10 h-10" v-else>
              <path stroke-linecap="round" stroke-linejoin="round" d="M6 18L18 6M6 6l12 12" />
            </svg>
          </span>

    <div class="w-full bg-white shadow-md h-screen py-5 xl:px-7 lg:px-3 px-1 md:static absolute top-1 flex flex-col space-y-8 transition ease-in-out duration-150 bg-fixed"
    :class="[menuOpen ? 'left-0 opacity-100' : 'left-[-100%]']"
    >
        <span  v-for="category in categories" :key="category.id" >
            <button class="flex space-x-4 bg-white" @click="categoryClicked(category.id)">
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="xl:w-6 xl:h-6 lg:w-4 lg:h-4 w-3 h-3">
                <path stroke-linecap="round" stroke-linejoin="round" d="M4.5 12h15m0 0l-6.75-6.75M19.5 12l-6.75 6.75" />
                </svg>
                <p v-if="language === 'en'" class="2xl:text-lg xl:text-sm text-xs font-light text-black/80 hover:text-black">{{category.name}}</p>
                <p v-else class="sm:text-lg text-xs font-light text-black/80 hover:text-black">{{category.name_am}}</p>
            </button>
        </span>
        
    </div> 
    </div>
    
</template>
<script>
export default {
    data () {
        return {
            // language: JSON.parse(localStorage.getItem('lang')).identifier,
            showCategories: false,
            menuOpen: false
        }
    },
    methods:{
        menu(){
            this.menuOpen = !this.menuOpen
        },
        categoryClicked(catID){
            this.$store.commit('setCategoryID', catID)
            this.$router.push({name:'Categories'})
        }
    },
   computed:{
        categories(){
            return this.$store.getters.getCategories
        },
        language () {
            return this.$store.getters.getLanguage.identifier
        }
   }
}
</script>