<template>
    <div class="w-full grid grid-cols-5 md:gap-x-5 gap-x-1 bg-transparent">
        <div class="flex flex-col space-y-3 items-center py-4 px-3 bg-white">
            <img class="w-9" src="@/assets/images/images.png"/>
            <p class="md:text-xl sm:text-base text-sm font-normal text-center">{{$t("fair_price")}}</p>
            <p class="md:text-sm text-xs font-light text-center">{{$t("quality_product_in_low_price")}}</p>
        </div>        
        <div class="flex flex-col space-y-3 items-center py-4 px-3 bg-white">
            <img class="w-9" src="@/assets/images/cashback.png"/>
            <p class="md:text-xl sm:text-base text-sm font-normal text-center">{{$t("loan_service")}}</p>
            <p class="md:text-sm text-xs font-light text-center">{{$t("you_can_buy_in_debt")}}</p>
        </div>
        <div class="flex flex-col space-y-3 items-center py-4 px-3 bg-white">
            <img class="w-9" src="@/assets/images/delivery.png"/>
            <p class="md:text-xl sm:text-base text-sm font-normal text-center">{{$t("free_transport")}}</p>
            <p class="md:text-sm text-xs font-light text-center">{{$t("free_delivery_in_your_place")}}</p>
        </div>        
        <div class="flex flex-col space-y-3 items-center py-4 px-3 bg-white">
            <img class="w-9" src="@/assets/images/premium.png"/>
            <p class="md:text-xl sm:text-base text-sm font-normal text-center">{{$t("large_number_of_items")}}</p>
            <p class="md:text-sm text-xs font-light text-center">{{$t("large_number_of_items_in_cheap_price")}}</p>
        </div>
        <div class="flex flex-col space-y-3 items-center py-4 px-3 bg-white">
            <img class="w-9" src="@/assets/images/hour.png"/>
            <p class="md:text-xl sm:text-base text-sm font-normal text-center">{{$t("customer_service")}}</p>
            <p class="md:text-sm text-xs font-light text-center">{{$t("guaranted_service_24_7")}}</p>
        </div>     
        
    </div>
</template>