// api
import OrderAPI from "@/services/Orders";

const state = {
    discounts: []
}

const getters = {
    getDiscounts(state){
        return state.discounts
    }
}

const actions = {
    async fetchDiscounts ({commit}){
        await OrderAPI.getDiscounts()
            .then((response)=>{
                console.log(response)
                commit('setDiscounts',response)
            })
    }
}

const mutations = {
    setDiscounts(state, discount){
        state.discounts = discount
    }
}

export default {
    state,
    getters,
    actions,
    mutations,
    namespaced: true
}