import { createApp } from 'vue'
import api from './axios.js'
import vueI18n from './locale/i18n'
import App from './App.vue'
import router from './router'
import store from './store'
import Toaster from '@meforma/vue-toaster';
import './firebase/index.js'
import './assets/tailwind.css'
import './interceptors/AxiosInterceptor.js'
import TawkMessengerVue from '@tawk.to/tawk-messenger-vue-3';
import vClickOutside from "click-outside-vue3"

const tawkProperties = {
  propertyId : '6368f430daff0e1306d6224a',
  widgetId : '1gh8vjo17'
}

const toastOption = {
    position: "top"
}

const app = createApp(App).use(store).use(vueI18n).use(vClickOutside).use(Toaster, toastOption).use(router).use(TawkMessengerVue,tawkProperties)
app.config.globalProperties.api = api
app.mount('#app')


