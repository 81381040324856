<template>
  <div class="w-full bg-gray-100 p-1">
    <!-- floating cart for when the user doesnt login but can add to the cart -->
    <div :key="cartKey" v-if="cartItems" class="sm:hidden fixed right-2 px-2 py-1 rounded-full bg-orangeButton shadow-lg cursor-pointer z-20">
        <div v-on:click="$router.push('/cart')" class="relative inline-flex cursor-pointer">
            <!-- cart svg -->
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="white" class="2xl:w-10 2xl:h-10 xl:w-8 xl:h-8  lg:w-6 lg:h-6 w-4 h-4">
                <path d="M2.25 2.25a.75.75 0 000 1.5h1.386c.17 0 .318.114.362.278l2.558 9.592a3.752 3.752 0 00-2.806 3.63c0 .414.336.75.75.75h15.75a.75.75 0 000-1.5H5.378A2.25 2.25 0 017.5 15h11.218a.75.75 0 00.674-.421 60.358 60.358 0 002.96-7.228.75.75 0 00-.525-.965A60.864 60.864 0 005.68 4.509l-.232-.867A1.875 1.875 0 003.636 2.25H2.25zM3.75 20.25a1.5 1.5 0 113 0 1.5 1.5 0 01-3 0zM16.5 20.25a1.5 1.5 0 113 0 1.5 1.5 0 01-3 0z" />
            </svg>
            <span class="absolute sm:text-base text-sm -top-2 -right-3 z-10 w-4 h-4 p-1 text-white bg-red-600 rounded-full border-none flex justify-center items-center" v-if="cartCount != 0">{{cartCount}}</span>
        </div>
    </div>
    <div class=" mt-2 grid grid-cols-6 xl:grid-rows-4 grid-rows-2 xl:grid-flow-col grid-flow-row sm:gap-x-3 sm:gap-y-4 gap-1 h-2/3 w-full relative">
      <SideBarComponent class="xl:row-span-4 md:row-span-2 row-span-1 md:static absolute top-0 bg-transparent row-start-1 col-start-1 z-10"/>
      <ProductSlideShow class="clo-start-2 xl:col-span-4 md:col-span-5 col-span-6 xl:row-span-3 row-span-1" />
       <advert-cards class="xl:row-start-4 row-start-2 xl:col-span-4 col-span-6"/>
      <RightSideBar class="row-span-4 xl:flex hidden" />
      
    </div>
    <div class="bg-orangeButton bg-opacity-40 flex flex-col justify-center mt-5 py-2">
      <p class="xl:text-3xl lg:text-xl text-base text-black text-center">{{$t("buy_from_choosen_categories")}}</p>
    </div>
    <!-- categories grid -->
    <div class="grid grid-cols-5 xl:gap-x-7 lg:gap-x-4 gap-x-1 w-full bg-gray-100 sm:px-7 px-1 py-4">
        <button v-for="category in categories" :key="category" @click="categoryClicked(category.id)" class="flex flex-col space-y-3 items-center justify-self-stretch rounded-md bg-white py-3 px-3">
          <img v-if="category.image_path" class="w-full sm:h-4/5 h-3/5 object-cover" :src="category.image_path"/>
          <img v-else class="sm:h-4/5 h-3/5 w-full" src="@/assets/images/No-Image-Placeholder.png"/>
          <p v-if="language === 'en'" class="self-start sm:text-xl text-xs text-black ml-1 text-center">{{category.name}}</p>
          <p v-else class="self-start sm:text-xl text-xs text-black ml-1 text-center">{{category.name_am}}</p>
        </button>
    </div>
    <div class="relative">
      <span class="absolute top-0 left-0 z-10 w-full h-full bg-gradient-to-r from-black opacity-70 pl-16 flex flex-col justify-center ">
        <p class="text-white opacity-100 sm:text-2xl text-base">{{$t("buy_package_from_one_place")}}</p>
      </span>
      <img class="mt-2 w-full bannerImage" src="@/assets/images/banner.png"/>
    </div>
    <div class="mt-3 bg-transparent sm:px-3 px-1 pt-2">
      <p class="sm:text-lg text-base">{{$t("best_selling_items")}}</p>
      <div class="grid sm:grid-cols-5 grid-cols-3 grid-flow-row sm:gap-x-5 gap-y-2 gap-x-2 w-full">
          <ItemsDetail @updateCart="updateCart" @updateUser="$emit('updateUser',1)" v-for="product in popularProducts.slice(0,5)" :key="product" :product="product" :language="language" />        
      </div>
      <!-- Orange pannel -->
      <div class="md:mx-4 sm:mx-2 mx-1 mt-6 mb-12 rounded-lg bg-orangeButton md:px-6 sm:px-3 px-2 py-8 flex md:flex-row flex-col sm:justify-around justify-between w-full space-y-4 items-center">
        <span class="sm:w-1/4 w-full flex sm:flex-col flex-row justify-around items-center">
          <p class="text-white sm:text-3xl text-xl font-semibold">{{$t("package_items")}}</p>
          <p class="text-white sm:text-xl text-base font-light">{{$t("shop_these_packages_with_discount")}}</p>
        </span>
        <div class="sm:w-2/4 w-full grid grid-cols-3 sm:gap-x-6 gap-x-1 grid-flow-row ">
          <!-- change this by Carousel -->
          <div v-for="pack in packages.slice(0,3)" :key="pack"
           class="bg-gray-100 flex flex-col justify-between rounded-md pb-5">
            <span class="flex flex-col space-y-5 h-2/3">
              <img class="h-2/3" :src="pack.image_path" />
              <span class="flex sm:flex-row flex-col justify-around">
                <p class="sm:text-base text-sm" v-if="language === 'en' || !pack.name_am">{{pack.name}}:</p>
                <p v-else>{{pack.name_am}}</p>
                <p class="font-semibold">{{pack.price}} {{$t("birr")}}</p>
              </span>
            </span>
            <button 
            v-on:click="goPackages(pack.id, pack.name)"
            class="bg-orangeButton rounded-3xl border-none hover:bg-white hover:border hover:border-orangeButton hover:text-orangeButton text-white sm:w-auto w-full sm:py-2 py-1 sm:px-4 px-2 text-xs self-center">{{$t("buy_now")}}</button>
          </div>          
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import SideBarComponent from '@/components/SideBarComponent.vue'
import ProductSlideShow from '@/components/ProductsSlideShow.vue'
import RightSideBar from '@/components/RightSideBar.vue'
import AdvertCards from '@/components/AdvertCards.vue'
import ItemsDetail from '@/components/ItemsDetail.vue'


export default {
  data () {
    return {
      // language: JSON.parse(localStorage.getItem("lang")).identifier,
      cartKey: 1,
    }
  },
  components: {
    SideBarComponent,
    ProductSlideShow,
    RightSideBar,
    AdvertCards,
    ItemsDetail 
  },
  computed:{
      cartCount(){
        return this.$store.getters.getCart.length
      },
      cartItems(){
        return this.$store.getters.getCart
      },
      categories(){
        return this.$store.state.categories 
      },
      popularProducts(){
        return this.$store.state.popularProducts
      },
      packages(){
        return this.$store.state.packages
      },
      language () {
          return this.$store.getters.getLanguage.identifier
      }
  },
  methods:{
    updateCart(value){
      this.cartKey = this.cartKey+value
    },
    goProducts(id,name) {
      this.$store.commit('setProductId', id)
      this.$router.push({ name: 'Product', params: { PID: name } })
    },
    goPackages(id,name){
      this.$store.commit('setPackageId', id)
      this.$router.push({name:'Package',params: {PID: name}})
    },
    categoryClicked(catID){
      this.$store.commit('setCategoryID', catID)
      this.$router.push({name:'Categories'})
    }
  },
 async created () {
    this.$store.commit('setRouteName', this.$route.name)
  }
}
</script>
<style>
.bannerImage{
  z-index: -1;
}
</style>
