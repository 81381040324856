import api from '@/axios.js'

class OrderAPI {
    // gets delivery types
    static getDeliveryTypes () {
        return new Promise(async(resolve,reject)=>{
            try {
                const res = await api.get('delivery-types')
                const data = res.data.data
                resolve(data)
            } catch (error) {
                reject(error)
            }
        })
    }
    // makes order
    static makeOrder(order){
        console.log(order)
        return new Promise(async(resolve,reject)=>{
            try {
                const res = await api.post('orders',
                    {
                        payment_method: order.payment_method,
                        delivery_type_id: order.delivery_type_id,
                        shipping_detail: order.shipping_detail,
                        discount_type_id: order.discount_type_id,
                        products: order.products,
                        packages: order.packages,
                    },
                    {
                        headers: {
                            'Authorization': 'Bearer '+ window.localStorage.getItem("token")
                        }
                     
                })                
                const data = res.data.data
                console.log(data)
                resolve(data)
            } catch (error) {
                console.log(error)
                reject(error)
            }
        })
    }
    // order with loan option pin
    static makeOrderPin(order){
        return new Promise(async(resolve,reject)=>{
            try {
                const res = await api.post('orders',
                    {
                        payment_method: order.payment_method,
                        delivery_type_id: order.delivery_type_id,
                        shipping_detail: order.shipping_detail,
                        discount_type_id: order.discount_type_id,
                        products: order.products,
                        packages: order.packages,
                        pin_code: order.pin_code
                    },
                    {
                        headers: {
                            'Authorization': 'Bearer '+ window.localStorage.getItem("token")
                        }
                     
                })                
                const data = res.data.data
                console.log(data)
                resolve(data)
            } catch (error) {
                console.log(error)
                reject(error)
            }
        })
    }
    // gets all orders the user made
    static getOrders(){
        return new Promise(async(resolve,reject)=>{
            try{
                const res = await api.get('orders',{
                    headers: {
                        'Authorization': 'Bearer '+ window.localStorage.getItem("token")
                    }
                })
                const data = res.data.data
                resolve(data)
            }catch(error){
                reject(error)
            }
        })
    }
    // gets order by status
    static getUserOrderByStatus(status){
        return new Promise(async(resolve,reject)=>{
            try {
                const res = await api.get('orders',{
                    headers: {
                        'Authorization': 'Bearer '+ window.localStorage.getItem("token")
                    },
                    data:{
                        status: status
                    }
                })
                const data = res.data.data
                resolve(data)
            } catch (error) {
                reject(error)
            }
        })
    }
    // gets orders by ID
    static getUserOrderById(orderId){
        return new Promise(async(resolve,reject)=>{
            try {
                const res = await api.get('orders/'+orderId,{
                    headers: {
                        'Authorization': 'Bearer '+ window.localStorage.getItem("token")
                    }
                })
                const data = res.data.data
                resolve(data)
            } catch (error) {
                reject(error)
            }
        })
    }
    // makes payment of order when order resource has beeen approved
    static makePayment(orderId){
        return new Promise(async(resolve,reject)=>{
            try{
                const res = await api.post('orders/'+orderId+'/make-payment',{},{
                    headers: {
                        'Authorization': 'Bearer '+ window.localStorage.getItem("token")
                    },
                })
                const data = res.data
                resolve(data)
            }catch(error){
                reject(error)
            }
        }) 
    }
    // makes payment of order when order resource has beeen approved and it is a loan
    static makePaymentOTP(orderId, otp){
        return new Promise(async(resolve,reject)=>{
            try{
                const res = await api.post('orders/'+orderId+'/make-payment',
                    {
                        otp: otp
                    },
                    {
                        headers: {
                            'Authorization': 'Bearer '+ window.localStorage.getItem("token")
                        },
                    })
                const data = res.data.data
                resolve(data)
            }catch(error){
                reject(error)
            }
        }) 
    }
    //resend order OTP
    static resendLoanOTP(orderId){
        return new Promise(async(resolve,reject)=>{
            try {
                const res = await api.post('/orders/'+orderId+'/resend-otp',{},{
                    headers: {
                        'Authorization': 'Bearer '+ window.localStorage.getItem("token")
                    },
                })
                const data = res.data
                resolve(data)
            } catch (error) {
                reject(error)
            }
        })
    }
    // if customer cancels the order let the server know
    static cancelOrder(orderId){
        return new Promise(async(resolve,reject)=>{
            try {
                const res = await api.post('orders/'+orderId+'/cancel',{},{
                    headers: {
                        'Authorization': 'Bearer '+ window.localStorage.getItem("token")
                    },
                })
                const data = res.data
                resolve(data)
            } catch (error) {
                reject(error)
            }
        })
    }
    // checks if order has been payed by CBE pay
    static checkCBE(checkoutID){
        return new Promise(async(resolve,reject)=>{
            try{
                const res = await api.post('orders/'+checkoutID+'/check-and-update-payment',{},{
                    headers: {
                        'Authorization': 'Bearer '+ window.localStorage.getItem("token")
                    },
                })
                const data = res.data.data
                resolve(data)
            }catch(error){
                reject(error)
            }
        })
    }
    // get discount types
    static getDiscounts(){
        return new Promise(async(resolve,reject)=>{
            try{
                const res = await api.get('discount-types', {
                    headers: {
                        'Authorization': 'Bearer '+ window.localStorage.getItem("token")
                    }
                })
                const data = res.data.data
                resolve(data)
            }catch(error){
                reject(error)
            }
        })
    }

}

export default OrderAPI