import api from '../axios'
import { createToaster } from "@meforma/vue-toaster";
import vueI18n from '../locale/i18n.js'

const toast = createToaster({position: "top"});

api.interceptors.response.use({},error =>{
    if(error){
        console.log(error)
        switch(error.code){            
            case "ERR_NETWORK":
                toast.error(vueI18n.global.t("check_internet_connection"))
                break;
            case "Unauthorized error":
                toast.error(vueI18n.global.t(""))
                break;
            case "ERR_BAD_REQUEST": 
                if(error.response.data.errors){
                    if('email' in error.response.data.errors){
                    toast.error(vueI18n.global.t("email_already_exists"))
                    }
                    else if('phone_number' in error.response.data.errors){
                        toast.error(vueI18n.global.t("phone_already_registered"))
                    }
                    else if('user_name' in error.response.data.errors){
                        toast.error(vueI18n.global.t("user_name_exist"))
                    }else{
                        console.log('unknown')
                    }
                }                
                else if("data" in error.response.data){
                    // do nothing
                }else{
                    if(error.response.data.message){
                        toast.error(vueI18n.global.t(error.response.data.message))
                    }
                    // toast.error(vueI18n.global.t("loan_service_unavailable"))
                }
               
                break;
            default:
                console.log("id dont think it is mentioned")
        }
        return Promise.reject(error)
    }
})
// // vueI18n.global.t