import api from '@/axios.js'

class ProductAPI{
    static getProducts(){
        return new Promise(async (resolve, reject)=>{
            try{
                const res = await api.get('products')
                const data = res.data
                resolve(data)
            }catch(error){
                reject(error)
            }
        })
    }
    static getPopularProducts(){
        return new Promise(async (resolve,reject)=>{
            try {
                const res = await api.get('popular-products')
                const data = res.data.data
                resolve(data)
            } catch (error) {
                reject(error)
            }
        })
    }

    static getProductsID(id){
        return new Promise(async (resolve, reject)=>{
            try{
                const res = await api.get('products/'+id)
                const data = res.data.data
                resolve(data)
            }catch(error){
                reject(error)
            }
        })
    }

    static searchProducts(name){
        return new Promise(async (resolve,reject)=>{
            try {
                const res = await api.get('products',{
                    params: {
                        name: name
                    }
                })
                const data = res.data.data
                resolve(data)
            } catch (error) {
                reject(error)
            }
        })
    }

    static getPackages(){
        return new Promise(async (resolve,reject)=>{
            try{
                const res = await api.get('packages')
                const data = res.data.data
                resolve(data)
            }catch(error){
                reject(error)
            }
        })
    }

    static getPackage(id){
        return new Promise(async (resolve,reject)=>{
            try{
                const res = await api.get('packages/'+id)
                const data = res.data.data
                resolve(data)
            }catch(error){
                reject(error)
            }
        })
    }
}

export default ProductAPI