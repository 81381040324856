import { createRouter, createWebHistory } from 'vue-router'
import HomeView from '../views/HomeView.vue'
import PaymentView from '../components/payment_pages/PaymentView.vue'
import TransportationView from '../components/payment_pages/TransportationView.vue'

const routes = [
  {
    path: '/',
    name: 'Home',
    component: HomeView
  },
  {
    path:'/bunna-terms-and-conditions',
    name: 'BunnaTerms',
    component: () => import('@/views/documents/BunnaLoanTerms.vue')
  },
  {
    path: '/login',
    name: 'Login',
    component: () => import('@/views/authentication/LoginView.vue')
  },
  {
    path: '/otp',
    name: 'OTP',
    component: () => import('@/views/authentication/OTP.vue'),
    beforeEnter: (to,from,next)=>{
      const isRedirected = from.name === 'Login'
      if(isRedirected){
        next()
      }else{
        next({ name: 'Home'})
      }
    }
  },
  {
    path:'/orderOTP',
    name:'OrderOTP',
    component: () => import('@/components/loanOTP.vue')
  },
  {
    path: '/change-pin',
    name: 'change_bunna_pin',
    component: () => import('@/components/AuthenticationComponents/ChangePinView.vue'),
    beforeEnter: (to,from,next)=>{
      var isRedirected = null 
      if(from.name){
        let fromString = from.name.toString() 
        console.log(fromString)
        isRedirected = fromString.match(/(bunna-pin|Checkout|Transportation|Payment|BunnaTerms)/g) 
      } 
      console.log(isRedirected)
      if(isRedirected){
        next()
      }else{
        next({path: '/'})
      }
    }
  },
  {
    path: '/enter-pin',
    name: 'bunna-pin',
    component: () => import('@/components/AuthenticationComponents/PinView.vue'),
    beforeEnter: (to,from,next)=>{
      var isRedirected = null 
      if(from.name){
        let fromString = from.name.toString() 
        isRedirected = fromString.match(/(change_bunna_pin|Checkout|Transportation|Payment|BunnaTerms)/g) 
      } 
      console.log(isRedirected)
      if(isRedirected){
        next()
      }else{
        next({path: '/'})
      }
    }
  },
  {
    path:'/awashOTP',
    name: 'AwashOTP',
    component: () => import('@/components/payment_pages/AwashOTPView.vue')
  },
  {
    path: '/history',
    name: 'History',
    component: () => import('@/views/OrdersHistory.vue')
  },
  {
    path: '/profile',
    name: 'Profile',
    component: () => import ('@/components/AuthenticationComponents/UserProfile.vue'),
  },
  {
    path: '/register',
    name: 'Register',
    component: () => import ('@/components/AuthenticationComponents/RegistrationForm.vue')
  },
  {
    path: '/address',
    name: 'Address',
    component: () => import('@/components/AuthenticationComponents/RegisterAddress.vue')
  },
  {
    path: '/edit',
    name: 'Edit',
    component: () => import ('@/components/AuthenticationComponents/EditProfileView.vue')
  },
  {
    path: '/product/:PID',
    name: 'Product',
    component: () => import('@/views/ProductView.vue')
  },
  {
    path: '/cart',
    name: 'Cart',
    component: () => import('@/views/CartView.vue')
  },
  {
    path: '/packages/:PID',
    name: 'Package',
    component: () => import('@/views/PackagesView.vue')
  },
  {
    path: '/favorites',
    name: 'Favourites',
    component: () => import('@/views/FavoritesView.vue')
  },
  {
    path: '/checkout',
    name: 'Checkout',
    component: () => import('@/views/CheckoutView.vue'),
    beforeEnter: (to,from,next)=>{
      var isRedirected = null 
      if(from.name){
        let fromString = from.name.toString() 
        isRedirected = fromString.match(/(OTP|Cart|Register|Address|Profile)/g) 
      } 
      console.log(isRedirected)
      if(isRedirected){
        if(JSON.parse(localStorage.getItem("user")).address){
          next()
        }else{
          next({path: '/address'})
        }
      }else{
        next({path: '/'})
      }
    } ,
    children: [
      {
        path: 'transportation',
        name: 'Transportation',
        component: TransportationView
      },
      {
        path: 'payment',
        name: 'Payment',
        component: PaymentView
      }
    ]
  },
  {
    path: '/categories',
    name: 'Categories',
    component: () => import('@/views/CategoriesView.vue')
  },
  {
    path: '/receipt/:orderID',
    name: 'Receipt',
    component: () => import('@/views/ReciptView.vue')
  },
  {
    path: '/cbebirr',
    name: 'Cbebirr',
    component: () => import('@/components/payment_pages/CBEbirrPage.vue')
  },
  {
    path: '/cbeBanking',
    name: 'CBEbanking',
    component: () => import('@/components/payment_pages/CBEBanking.vue')
  },
  {
    path: '/apollo',
    name: 'Apollo',
    component: () => import('@/components/payment_pages/ApolloWalletView.vue')
  },
  {
    path: '/:pathMatch(.*)*',
    name: 'not_found',
    component: () => import('@/views/NotFoundPage.vue')
  },
  
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
