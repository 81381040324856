<template>
    <div class="flex flex-col items-center justify-center bg-black/70">
        <div 
            :class="[openImage ? 'translate-y-0' : 'translate-y-full']" 
            class="relative xl:w-2/5 lg:w-1/2 md:w-2/3 w-3/4 transition-transform ease-in-out"
        >
            <div class="absolute top-0 w-full pr-2 pt-2 flex justify-end">
                <div class="p-1 group bg-transparent group-hover:bg-red-500 transition-color ease-in-out rounded-full">
                    <svg @click="$emit('closePopup')" class="text-white group-hover:rotate-45 transition-transform cursor-pointer" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 512 512"><path fill="currentColor" d="m289.94 256l95-95A24 24 0 0 0 351 127l-95 95l-95-95a24 24 0 0 0-34 34l95 95l-95 95a24 24 0 1 0 34 34l95-95l95 95a24 24 0 0 0 34-34Z"/></svg>
                </div>                
            </div>
            <img class="object-contain w-full" :src="popup.image_path" />
        </div>
    </div>
</template>
<script>
export default {
    props: {
        popup: Object,
    },
    data () {
        return {
            openImage: false
        }
    },
    mounted () {
        setTimeout(()=>{
            this.openImage = true
        },600)
    },
    beforeUnmount () {
        this.openImage = false
        setTimeout(()=>{
            console.log('t')
        }, 300)
    }
}
</script>