<template>
    <transition name="slide-in">
        <div class="absolute top-0 left-0 right-0 bottom-0 flex flex-col items-center justify-center" v-show="currentSlide === index">
            <img class="md:h-full h-auto md:w-auto w-full" :src="slideImage"/>
        </div>
    </transition>
</template>
<script>
export default {
    props: ["slideImage","title", "promotion","currentSlide","index"],
    computed: {
        language () {
            return this.$store.getters.getLanguage.identifier
        }
    }
}
</script>
<style>
.slide-in-enter-active,
.slide-in-leave-active{
    transition: all 1s ease-in-out;
}

.slide-in-enter-from {
    transform: translateX(-100%);
}

.slide-in-leave-to{
    transform: translateX(100%)
}
</style>