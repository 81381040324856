import api from '@/axios.js'

class CategoriesAPI{
    // returns all categories
    static getCategories(){
        return new Promise (async (resolve,reject)=>{
            try {
                const res = await api.get('categories')
                const data = res.data.data
                resolve(data)
            } catch (error) {
                reject(error)
            }
        })
    }
    // returns all subcategories within a category if any
    static getSubcategories(catID){
        return new Promise(async(resolve,reject)=>{
            try{
                const res = await api.get('categories/'+catID+'/subcategories')
                const data = res.data.data
                resolve(data)
            }catch(error){
                reject(error)
            }
        })
    }
    // gets all the products within the specified category id
    static getProductsByCategories(catID,page){
        return new Promise(async(resolve,reject)=>{
            try{
                const res = await api.get('categories/'+catID+'/products',{
                    params:{
                        page: page
                    }
                })
                const data = res.data
                resolve(data)
            }catch(error){
                reject(error)
            }
        })
    }

    // gets all the products within the specified subcategory id
    static getProductsBySubcategories(subCatID,page){
        return new Promise(async(resolve,reject)=>{
            try {
                const res = await api.get('subcategories/'+subCatID+'/products',{
                    params:{
                        page: page
                    }
                })
                const data = res.data
                resolve(data)
            } catch (error) {
                reject(error)
            }
        })
    }
}
export default CategoriesAPI