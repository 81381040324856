<template>
    <div class="w-full flex flex-col items-center">
        <div class="sm:w-2/3 w-full h-1/2">
            <p class="text-3xl font-medium">{{$t("payment_options")}}</p>
            <div class="max-h-[66vh] mt-3 pb-2 sm:px-0 px-3 w-full overflow-y-auto orangeScrollbar grid grid-cols-2 grid-flow-row gap-x-7 gap-y-3">
                <div 
                :class="[paymentOption == 1 ? 'border-4 border-orangeButton scale-105 shadow-xl' : 'border border-black border-opacity-60 scale-100']"
                @click="paymentOption = 1"
                class="md:h-40 h-20 w-full bg-white shadow-sm hover:shadow-xl transform hover:scale-105 rounded">
                    <img src="@/assets/images/telebirr-logo.png" alt="">
                </div>
                <div 
                :class="[paymentOption == 2 ? 'border-4 border-orangeButton scale-105 shadow-xl' : 'border border-black border-opacity-60  scale-100']"
                @click="paymentOption = 2"
                class="md:h-40 h-20 w-full bg-white shadow-sm hover:shadow-xl transform hover:scale-105 rounded">
                    <img src="@/assets/images/cbe-pay.jpg" alt="">
                </div>
                <div
                :class="[paymentOption == 3 ? 'border-4 border-orangeButton scale-105 shadow-xl' : 'border border-black border-opacity-60 scale-100']"
                @click="paymentOption = 3"
                class="md:h-40 h-20 overflow-hidden w-full bg-white shadow-sm hover:shadow-xl transform hover:scale-105 rounded">
                    <img class="md:h-36 h-16 bg-center" src="@/assets/images/hellocash-wegagen.jpg" alt="">
                </div>
                <div
                :class="[paymentOption == 5 ? 'border-4 border-orangeButton scale-105 shadow-xl' : 'border border-black border-opacity-60 scale-100']"
                @click="paymentOption = 5"
                class="md:h-40 h-20 w-full bg-white shadow-sm hover:shadow-xl transform hover:scale-105 rounded">
                    <img class="md:h-36 h-16 ml-2 " src="@/assets/images/birr.png" alt="">
                </div>
                <div
                :class="[paymentOption == 7 ? 'border-4 border-orangeButton scale-105 shadow-xl' : 'border border-black border-opacity-60 scale-100']"
                @click="paymentOption = 7"
                class="md:h-40 h-20 flex items-center w-full bg-[#4E1B56] shadow-sm hover:shadow-xl transform hover:scale-105 rounded">
                    <img class="w-1/3" src="@/assets/images/cbeBanking.png" alt="">
                    <p class="ml-3 md:text-lg text-sm font-medium text-white">CBE Mobile Banking</p>
                </div>
                <div
                :class="[paymentOption == 6 ? 'border-4 border-orangeButton scale-105 shadow-xl' : 'border border-black border-opacity-60 scale-100']"
                @click="paymentOption = 6"
                class="md:h-40 h-20 w-full bg-white shadow-sm hover:shadow-xl transform hover:scale-105 rounded">
                <img class="md:h-36 h-16" src="@/assets/images/awash_logo.png" alt="">
                </div>
                <div
                :class="[paymentOption == 8 ? 'border-4 border-orangeButton scale-105 shadow-xl' : 'border border-black border-opacity-60 scale-100']"
                @click="paymentOption = 8"
                class="md:h-40 h-20 w-full bg-[#F3AC12] flex flex-col items-center shadow-sm hover:shadow-xl transform hover:scale-105 rounded">
                <img class="md:h-36 h-16" src="@/assets/images/abyssinia_logo.png" alt="">
                </div>
                <div
                :class="[paymentOption == 9 ? 'border-4 border-orangeButton scale-105 shadow-xl' : 'border border-black border-opacity-60 scale-100']"
                @click="paymentOption = 9"
                class="md:h-40 h-20 w-full bg-[#8E3014] flex flex-col items-center shadow-sm hover:shadow-xl transform hover:scale-105 rounded">
                <img class="md:h-36 h-16" src="@/assets/images/apollo_logo.png" alt="">
                </div>
                <div
                :class="[paymentOption == 10 ? 'border-4 border-orangeButton scale-105 shadow-xl' : 'border border-black border-opacity-60 scale-100']"
                @click="paymentOption = 10"
                class="md:h-40 h-20 w-full bg-white flex flex-col items-center shadow-sm hover:shadow-xl transform hover:scale-105 rounded overflow-hidden">
                <img class="md:h-48 h-16" src="@/assets/images/enat_bank_logo.png" alt="">
                </div>
                <div
                :class="[paymentOption == 11 ? 'border-4 border-orangeButton scale-105 shadow-xl' : 'border border-black border-opacity-60 scale-100']"
                @click="paymentOption = 11"
                class="md:h-40 h-20 w-full bg-white flex flex-col items-center shadow-sm hover:shadow-xl transform hover:scale-105 rounded overflow-hidden">
                <img class="md:h-48 h-16" src="@/assets/images/ethswitch_logo.png" alt="">
                </div>
                <div 
                v-if="userPresent.bank && userPresent.loan_balance > 0"
                :class="[paymentOption == 4 ? 'border-4 border-orangeButton scale-105 shadow-xl' : 'border border-black border-opacity-60 scale-100']"
                class="md:h-40 h-20 w-full bg-white shadow-sm hover:shadow-xl transform hover:scale-105 flex items-center cursor-pointer" 
                @click="paymentOption = 4" >
                    <img class="w-1/3" src="@/assets/images/bunna_logo.png" />
                    <p class="ml-2 basis-2/3 text-center md:text-2xl text-xs font-semibold">{{$t("your_loan_balance")}}:<br/><span class="md:text-xl text-sm font-semibold">{{userPresent.loan_balance}}</span> </p>
                </div>
                <div v-else-if="userPresent.loan_balance <=0 && userPresent.corporate_id">
                    <p class="md:text-lg text-sm font-semibold">{{$t("insufficient_balance")}}</p>
                    <p class="md:text-base text-xs text-black text-opacity-70">{{$t("insufficient_balance_description")}}</p>
                </div>
                <div v-else-if="userPresent.bank && !userPresent.loan_balance">
                    <p class="md:text-lg font-semibold">{{$t("error_fetch_loan")}}</p>
                    <p class="md:text-base text-black text-opacity-70">{{$t("error_fetch_loan_description")}}</p>
                </div>
                <div @click="notCorporateToast()" v-else>
                    <p class="md:text-lg font-semibold">{{$t("no_loan_balance")}}</p>
                    <p class="md:text-base text-black text-opacity-70"><br/>{{$t("no_loan_balance_description")}}</p>
                </div>
            </div>
            
        </div>
    </div>
</template>
<script>
export default {
    data () {
        return {
            paymentOption: 0,
            phoneNumberHint: this.$t("phone_no_hint"),
            anotherPhoneNumber: '',
            err: '',
        }
    },
    methods: {
        reset() {
            this.err = ''
            this.isValidPhone = false
        },
        notCorporateToast () {
            this.$toast.warning(this.$t("not_corporate_description"))
        },
        goHelloCash(){
            if(this.isValidPhone()){
                this.$emit('updatePhone', {"phone_number": this.anotherPhoneNumber})
            }else{
                this.err = this.$t("phone_no_error")
            }
            
        }
    },
    computed: {
        userPresent() {
            return JSON.parse(localStorage.getItem("user"))
        },
        isValidPhone (){
            return !this.anotherPhoneNumber.length || /^0?9\d{8}$/.test(this.anotherPhoneNumber)
        }
    },
    watch:{
        paymentOption(value){
                this.$emit('updatePayment', value)            
        },
        anotherPhoneNumber(){
            if(this.anotherPhoneNumber.length > 10) {
                this.anotherPhoneNumber = this.anotherPhoneNumber.slice(0,10)
            }
        }
    }
}
</script>