<template>
    <div class="flex flex-col justify-between bg-white rounded-md cursor-pointer shadow-sm hover:shadow-lg transform hover:scale-110">
          <img v-if="product.image_paths[0]"  v-on:click="goProducts(product.id, product.name)" class="md:h-2/3 h-3/5" :src="product.image_paths[0]"/>
          <img v-on:click="goProducts(product.id, product.name)" v-else class="md:h-2/3 h-3/5" src="@/assets/images/No-Image-Placeholder.png"/>
          <span class="flex justify-between items-center px-2">
            <span class="flex flex-col space-y-2">
              <p class="xl:text-xl lg:text-base text-sm font-extrabold" v-if="language === 'en'">{{product.name}}</p>
              <p class="xl:text-xl lg:text-base text-sm font-extrabold" v-else>{{product.name_am}}</p>
              <p v-if="detail && product.discount > 0.00" class="xl:text-xl lg:text-base text-sm line-through decoration-red-500 text-red-500 font-light">{{product.price}} {{$t("birr")}}</p>
              <p v-if="detail" class="xl:text-xl lg:text-base text-sm font-medium">{{product.price -product.discount}} {{$t("birr")}}</p>
              <p v-if="!detail" class="xl:text-xl lg:text-base text-sm font-medium">{{product.price - product.discount}} {{$t("birr")}}</p>  
            </span>
            <span class="flex flex-col items-center md:space-y-2 space-y-1 ">
                <span class="flex md:space-x-2 space-x-1 items-start ">
                            <!-- minus sign  -->
                            <svg v-on:click="changeQuantity(1)" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="xl:w-6 xl:h-6 lg:w-4 lg:h-4 w-3 h-3 cursor-pointer bg-gray-200">
                                <path stroke-linecap="round" stroke-linejoin="round" d="M19.5 12h-15" />
                            </svg>
                            <p class="border-b md:p-0 p-[2px] md:text-xl text-xs">{{amount}}</p>
                            <!-- <span class="flex flex-col">
                                <svg @click="changeQuantity(2)" class="xl:w-10 xl:h-10 lg:w-4 lg:h-4 w-3 h-3 cursor-pointer" xmlns="http://www.w3.org/2000/svg" preserveAspectRatio="xMidYMid meet" viewBox="0 0 24 24"><path fill="currentColor" d="M8.71 12.29L11.3 9.7a.996.996 0 0 1 1.41 0l2.59 2.59c.63.63.18 1.71-.71 1.71H9.41c-.89 0-1.33-1.08-.7-1.71z"/></svg>
                                <svg @click="changeQuantity(1)" class="md:-mt-4 -mt-1 xl:w-10 xl:h-10 lg:w-4 lg:h-4 w-3 h-3 cursor-pointer" xmlns="http://www.w3.org/2000/svg"  preserveAspectRatio="xMidYMid meet" viewBox="0 0 24 24"><g transform="translate(0 24) scale(1 -1)"><path fill="currentColor" d="M8.71 12.29L11.3 9.7a.996.996 0 0 1 1.41 0l2.59 2.59c.63.63.18 1.71-.71 1.71H9.41c-.89 0-1.33-1.08-.7-1.71z"/></g></svg>
                            </span> -->
                            <!-- plus sign  -->
                            <svg v-on:click="changeQuantity(2)" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="xl:w-6 xl:h-6 lg:w-4 lg:h-4 w-3 h-3 bg-gray-200 cursor-pointer">
                                <path stroke-linecap="round" stroke-linejoin="round" d="M12 4.5v15m7.5-7.5h-15" />
                            </svg>
                        </span>
              <!-- added to cart check -->
              <svg class="xl:w-12 xl:h-12 lg:w-8 lg:h-8 w-5 h-5 self-end" v-if="addedCart" v-on:click="addCart(false)" xmlns="http://www.w3.org/2000/svg" preserveAspectRatio="xMidYMid meet" viewBox="0 0 16 16"><path fill="orange" d="M.5 1a.5.5 0 0 0 0 1h1.11l.401 1.607l1.498 7.985A.5.5 0 0 0 4 12h1a2 2 0 1 0 0 4a2 2 0 0 0 0-4h7a2 2 0 1 0 0 4a2 2 0 0 0 0-4h1a.5.5 0 0 0 .491-.408l1.5-8A.5.5 0 0 0 14.5 3H2.89l-.405-1.621A.5.5 0 0 0 2 1H.5zM6 14a1 1 0 1 1-2 0a1 1 0 0 1 2 0zm7 0a1 1 0 1 1-2 0a1 1 0 0 1 2 0zm-1.646-7.646l-3 3a.5.5 0 0 1-.708 0l-1.5-1.5a.5.5 0 1 1 .708-.708L8 8.293l2.646-2.647a.5.5 0 0 1 .708.708z"/>
              </svg>
              <!-- add to cart -->
              <svg class="xl:w-12 xl:h-12 lg:w-8 lg:h-8 w-5 h-5 self-end" v-else v-on:click="addCart(true)" xmlns="http://www.w3.org/2000/svg" width="48" height="48" preserveAspectRatio="xMidYMid meet" viewBox="0 0 24 24"><g fill="none" stroke="orange" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"><circle cx="6" cy="19" r="2"/><circle cx="17" cy="19" r="2"/><path d="M17 17H6V3H4"/><path d="m6 5l6.005.429m7.138 6.573L19 13H6m9-7h6m-3-3v6"/></g>
              </svg>
            </span> 
          </span>             
         </div>
</template>
<script>
export default {
    props: ['product', 'language','detail'],
    data () {
        return {
            addedCart: false, 
            amount: 1,
            productQuantity: 1,
        }
    },
    computed:{
        cartItems(){
           return this.$store.getters.getCart
        },
    },
    methods: {
        goProducts(id,name) {
            if(this.product.left_in_stock > this.amount && this.product.left_in_stock > 0){
                this.$store.commit('setProductId', id)
                this.$router.push({ name: 'Product', params: { PID: name } })
            }else{
                this.$toast.error(this.$t('out_of_stock'))
            }
           
        },
        changeQuantity (direction){
            var maxAmount = this.product.max_quantity_per_order
            var userBypassAllowed = JSON.parse(localStorage.getItem('user'))?.bypass_product_quantity_restriction

            if(direction === 1 && this.amount > 1){
                this.amount -= 1
            }
            if(direction ===2 && this.amount < this.product.left_in_stock){
                if(maxAmount){
                    if(this.amount < maxAmount || userBypassAllowed){
                        this.amount += 1
                    }else{
                        this.$toast.warning(this.$t('limit_reached'))
                    }
                }else{
                    this.amount +=1
                }
            
            }
        },
        addCart(added){
            var name
            if(this.language == 'en'){
                name = this.product.name
            }else{
                name = this.product.name_am
            }
            if(added && this.product.left_in_stock > 0){
                this.$store.commit('incrementCartCount', 1),
                this.$store.dispatch('newCartItem', {"product":this.product,"amount":this.amount})
                this.addedCart = true                
                this.$toast.success(this.amount+' '+name+' '+this.$t("product_added_description"))
                this.$emit('updateCart',1)   
                this.$emit('updateUser',1)
            }
            else{
                this.$toast.error(this.$t('out_of_stock'))
            } 
            setTimeout(()=>{
                this.addedCart = false
            },500)
            
        },
    },
    created () {
        var index = this.cartItems.findIndex(e => e.product.id === this.product.id)
        if(index > -1){
            this.amount = this.cartItems[index].amount
        }else{
            this.amount = 1
        }
    }
}
</script>