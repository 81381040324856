import Echo from "laravel-echo";
import Pusher from "pusher-js";
// api 
import UserAPI from "@/services/User.js";
import NotificationAPI from "@/services/Notifications";
// toast notification and language
import { createToaster } from "@meforma/vue-toaster";
import vueI18n from '@/locale/i18n.js'


const toast = createToaster({position: 'top'})

const state = {
    notifications: [],
    adverts: [],
    popUps: [],
    newNotification: 0
}

const getters = {
    bringNotifications(state){
        return state.notifications
    },
    newNotification(state){
        return state.newNotification
    },
    getAdverts (state) {
        return state.adverts
    },
    getPopUps (state) {
        return state.popUps
    }
}

const actions = {
    getNotifications({state,dispatch}){
        if(state.notifications.length == 0){
            dispatch('fetchNotifications')
        }       
    },
    initalizeSocket({state,commit}){
        try{
            // initializing socket connection
            window.Pusher = Pusher;
            window.Echo = new Echo({
            broadcaster: "pusher",
            key: "local",
            wsHost: "www.socket.seregelagebeya.com",
            forceTLS: true,
            disableStats: true,
            authEndpoint: "https://api.seregelagebeya.com/api/broadcasting/auth",
            auth: {
                headers: {
                Authorization: 'Bearer '+ window.localStorage.getItem('token') ? 'Bearer '+window.localStorage.getItem('token') :''
                },
            },
            });

          // getting private notifications
          if(window.localStorage.getItem('token')){
            window.Echo.private('users.' + JSON.parse(localStorage.getItem('user')).id).notification((notification) =>{
                console.log('we have recieved new notification')
                console.log(notification)
                commit('ADDNOTIFICATION',{'notification':notification,'alter_type':2})
                state.newNotification = 1
                toast.success(1+' '+vueI18n.global.t('notifications'))                
          }) 
          }

          // getting public notifications
          window.Echo.channel('company.public.push').listen('PushAdvertSent',(notification)=>{
            commit('ADDNOTIFICATION',{'notification':notification,'alter_type':3})
            state.newNotification = 1
          })
          
        }catch(error){
            console.log(error)
        }
        
    },
    async fetchNotifications({commit}){
        await UserAPI.getNotifications()
            .then((response)=>{
                console.log(response)
                commit('SETNOTIFICATIONS',response)
            })
            .catch(error=>{
                console.log(error)
            })
    },
    async fetchAdverts ({commit}) {
        var success = false
        await NotificationAPI.getImageAdvert()
            .then((result) =>{
               commit('addAdvert', result)
               success = true
            })
            .catch((error)=>{
                success = false
                console.log(error)
            })
            return {success: success}
    } 
}

const mutations = {
    // set entire notifications array
    SETNOTIFICATIONS: (state,notification)=>{
        for(var not in notification){
            notification[not].alter_type = 1
        }
        state.notifications = notification
    },
    // add new notification to top of the array
    ADDNOTIFICATION: (state,{ notification, alter_type}) =>{
        notification.alter_type = alter_type
        notification.created_at = new Date()
        state.notifications.unshift(notification)
    },
    // used to turn of new notification indicator
    RESETNEWNOTIFICATION: (state)=>{
        state.newNotification = 0
    },
    addAdvert: (state, newAdverts) => {
        newAdverts.forEach((advert)=>{
            if(advert.is_popup) {
                state.popUps.unshift(advert)
            }else{
                state.adverts.unshift(advert)
            }
        })
    }
}


export default {
    state,
    getters,
    actions,
    mutations,
    namespaced: true
}