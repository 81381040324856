import { createStore } from 'vuex'
import { createToaster } from "@meforma/vue-toaster";
import vueI18n from '../locale/i18n.js'
// modules
import notification from './modules/notification.js';
import auth from './modules/auth.js';
import discount from './modules/Discount.js';

const toast = createToaster({position: "top"});

export default createStore({
  state: {
    routeName : '',
    popularProducts: [],
    categories: [],
    categoryID: 0,
    cartCount: 0,
    cartItems: [],
    userDetail: {},
    orderResource: {},
    userPresent: 1,
    searchResult: [],
    userFavourites: [],
    packages: [],
    orderId: 0,
    language: {"display": "English","identifier": 'en'},
  },
  getters: {
    getLanguage (state) {
      return state.language
    },
    getOrderResource(state){
      return state.orderResource
    },
    getCart(state){      
        if(localStorage.getItem('cart')){
          state.cartItems = JSON.parse(localStorage.getItem('cart'))
        }else{
          state.cartItems = []
        }
        return state.cartItems        
    },
    getFavorites(state){
      if(localStorage.getItem('favorites')){
        state.userFavourites = JSON.parse(localStorage.getItem('favorites'))
      }else{
        state.userFavourites = []
      }
      return state.userFavourites
    },
    getCartCount (state){
      return state.cartItems.length
    },
    getOrderId(state){
      return state.orderId
    },
    getCategories (state){
      return state.categories
    },
    getProductId(){
      return localStorage.getItem("productId")
    },
    getPackageId() {
      return  localStorage.getItem("packageId")
    },
    totalPrice (state,getters){ 
      let totalCount = 0
      let totalBill = 0
      let totalDiscount = 0
      var cart = getters.getCart
      for (let item in cart){
          totalCount += cart[item].amount
          totalBill += cart[item].amount *(cart[item].product.price - cart[item].product.discount)
          totalDiscount += parseFloat(cart[item].product.discount)
      }      
    return {"totalCount": totalCount,"totalBill": totalBill, "totalDiscount": totalDiscount}
  }
  },
  mutations: {
    setLanguage(state,lang) {
      state.language = lang
    },
    // set order resource
    setOrderResource(state, resource){
      state.orderResource = resource
    },
    // sets product new to the route
    setRouteName (state, newRoute){
      state.routeName = newRoute
    },
    // route for setting receipt data
    async setReceipt(state, data){
      localStorage.setItem('receipt', data)
    },
    // for the number appearing over the cart
    incrementCartCount (state, count){
      state.cartCount += count 
    },
    SETORDERID(state, id){
      localStorage.setItem("orderID", id)
      state.orderId = id
    },
    
    // sets the catagories 
    setCategories(state, categories){
      state.categories = categories
    },
    // sets the category id for catagories page
    // should be presistent
    setCategoryID(state, catID){
      localStorage.setItem("catID", catID)
    },
    // sets the popular products
    setPopularProducts(state, products){
      state.popularProducts = products
    },
    // sets the product id for when loading on page
    // should be presistent
    setProductId(state,id){
      localStorage.setItem("productId", id)
    },
    // sets the package id for when opening the package in its own page
    setPackageId(state,id){
      localStorage.setItem("packageId", id)
    },
    // sets search result to display 
    // is it that neccessary check
    setSearchResult(state,result){
      state.searchResult = []
      state.searchResult = result
    },
    // sets packages when the main screen loads
    setPackages(state,packages){
      state.packages = packages
    },
    // when favourites button is pressed it checks if it is present or not so to add or subtract that item
    // this can replace subtract favourtie mutation above
   
  },
  actions: {
    newCartItem({state,getters}, item) {
      var breakFlag = 0  
      let cart = getters.getCart   
      if(cart){
        for(let items in cart){
          if(cart[items].product.id == item.product.id){
            cart[items].amount = item.amount
            breakFlag = 1
          }
        }
        if(!breakFlag){
          cart.push(item) 
        }             
      }else{
        cart.push(item)
      }
      localStorage.setItem("cart", JSON.stringify(cart))
      state.cartItems = cart
    },
    subtractItem ({state,getters}, index){
      let cart = getters.getCart
      cart.splice(index,1)
      localStorage.setItem("cart", JSON.stringify(cart)) 
      state.cartItems = cart         
    },
    toogleFavourites ({state,getters}, item){
      let favorites = getters.getFavorites
      const i = favorites.findIndex(e => e.product.id === item.product.id)
      if(i > -1){
        favorites.splice(i,1)
        toast.warning(vueI18n.global.t("package_removed"))
      }else{
        favorites.push(item)
        toast.success(vueI18n.global.t("package_added"))
      }
      localStorage.setItem('favorites', JSON.stringify(favorites))
      state.userFavourites = favorites
    },
    // subtracts item from the favourites screen
    subtractFavorite({state,getters},index){
      let favorites = getters.getFavorites

      favorites.splice(index,1)

      localStorage.setItem('favorites', favorites)
      state.userFavourites = favorites
    },
  },
  modules: {
    auth:auth,
    notification:notification,
    discount:discount
  }
})
